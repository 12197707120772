const defaultClientHeaderOrders = [
  "website",
  "email",
  "industry",
  "accountOwner",
  "designation",
  "status",
  "created_at",
];

const clientHeaderShow = {
  website: true,
  email: false,
  industry: true,
  designation: false,
  accountOwner: true,
  status: true,
  created_at: true,
};

const defaultContactHeaderOrders = [
  "email",
  "phone",
  "designation",
  "accountName",
  "created_by",
  "modified_by",
  "created_at",
  "updated_at",
];
const showTableColumn = {
  lastName: true,
  email: true,
  phone: true,
  designation: false,
  accountName: true,
  created_by: true,
  modified_by: true,
  created_at: true,
  updated_at: true,
};

const defaultJobHeaderOrders = [
  "jobtitle",
  "clientname",
  "city",
  "state",
  "contactperson",
  "owner_name",
  "accountmanager",
  "recruiter",
  "teamleader",
  "skill",
  "status",
  "created_at",
  "industry",
];

const jobHeaderShow = {
  clientname: true,
  jobtitle: true,
  city: true,
  state: true,
  contactperson: true,
  accountmanager: true,
  recruiter: true,
  teamleader: true,
  skill: true,
  status: true,
  owner_name: true,
  created_at: true,
  industry: false,
};

const defaultCandidateHeaderOrders = [
  "fullName",
  "email",
  "phone",
  "designation",
  "experiance",
  "skill",
  "owner_name",
  "city",
  "state",
  "country",
  "status",
  "candidatestatus",
  "source",
  "created_at",
  "degree",
  "companyname",
  "Companydesignation",
  "aadharcardnumber",
  "pancardnumber",
  "gender",
  "pincode",
  "address",
  "DOB",
  "currentCandidateCTCType",
  "currentCandidateCTC",
  "expectedCandidateCTCType",
  "expectedCandidateCTC",
  "sendMail",
  "updatedBy",
  "industry",
];

const candidateHeaderShow = {
  email: true,
  phone: true,
  designation: true,
  experiance: true,
  skill: true,
  owner_name: true,
  city: true,
  state: true,
  country: true,
  status: true,
  candidatestatus: true,
  source: true,
  created_at: true,
  degree: false,
  companyname: false,
  Companydesignation: false,
  aadharcardnumber: false,
  pancardnumber: false,
  gender: false,
  DOB: false,
  sendMail: false,
  currentCandidateCTCType: false,
  currentCandidateCTC: false,
  expectedCandidateCTCType: false,
  expectedCandidateCTC: false,
  updatedBy: false,
  industry: false,
};

const defaultSubmHeaderOrders = [
  "fullName",
  "firstName",
  "lastName",
  "email",
  "phone",
  "jobtitle",
  "status",
  "client",
  "owner_name",
  "currentCandidateCTCType",
  "currentCandidateCTC",
  "expectedCandidateCTCType",
  "expectedCandidateCTC",
  "noticePeriod",
  "city",
  "state",
  "submission_at",
  "gender",
  "address",
  "DOB",
  "aadharcardnumber",
  "pancardnumber",
  "designation",
  "experience",
  "workExperience",
  "educationDetails",
  "interviewScheduleDate",
  "interviewScheduleTime",
  "interviewScheduleLocation",
  "interviewScheduleLink",
  "joinDate",
  "actualCTC",
  "expectedJoinDate",
  "offerCTC",
  "submissionBy",
  "updated_at",
  "updatedBy",
  "industry",
  "interviewRescheduleDate",
  "testRescheduleDate",
  "backoutDate",
  "source"
];

const submCandidateHeaderShow = {
  fullName: false,
  lastName: true,
  email: true,
  phone: true,
  jobtitle: true,
  status: true,
  client: true,
  owner_name: true,
  currentCandidateCTCType: false,
  currentCandidateCTC: true,
  expectedCandidateCTCType: false,
  expectedCandidateCTC: true,
  noticePeriod: true,
  city: true,
  state: true,
  submission_at: true,
  gender: true,
  address: false,
  DOB: false,
  aadharcardnumber: false,
  pancardnumber: false,
  designation: false,
  experience: false,
  workExperience: false,
  educationDetails: false,
  interviewScheduleDate: false,
  interviewScheduleTime: false,
  interviewScheduleLocation: false,
  interviewScheduleLink: false,
  joinDate: false,
  actualCTC: false,
  expectedJoinDate: false,
  offerCTC: false,
  submissionBy: true,
  industry: false,
  interviewRescheduleDate: false,
  testRescheduleDate: false,
  backoutDate: false,
  source:true
};

const ownershipChatOption = {
  chart: {
    type: "bar",
    height: 100,
    zoom: {
      enabled: true,
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 4,
      borderRadiusApplication: "end",
      horizontal: true,
      barHeight: "50%",
    },
  },
  colors: ["#33b2df", "#546E7A", "#d4526e"],
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    categories: ["Jobs", "Candidates", "Submissions"],
  },
};

const FunnelChartOptions = {
  chart: {
    width: 380,
    type: "pie",
  },
  labels: ["Submissions", "Rejected", "Selected", "Join"],
  colors: ["#FF4560", "#00E396", "#FEB019", "#008FFB", "#775DD0"],
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
};

const ColumnChartsObject = {
  chart: {
    height: 550,
    type: "bar",
  },
  plotOptions: {
    bar: {
      borderRadius: 5,
      columnWidth: "30%",
      dataLabels: {
        position: "top", // top, center, bottom
      },
    },
    colors: {
      backgroundBarColors: ["#FF4560"],
      backgroundBarOpacity: 1,
    },
  },
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return val;
    },
    offsetY: -20,
    style: {
      fontSize: "12px",
      colors: ["#023f61"],
    },
  },
  stroke: {
    width: 0,
  },
  grid: {
    row: {
      colors: ["#fff", "#f2f2f2"],
    },
  },
  yaxis: {
    title: {
      text: "Values",
    },
  },
};

const totalModules = [
  "QA Check",
  "Interview Attained",
  "Rejected",
  "Selected",
  "Joined",
  "Backout",
  "Submission",
  "Interview Schedule",
  "Interview Complete",
  "Pending",
].map((item) => ({ label: item, value: item }));

export {
  defaultClientHeaderOrders,
  clientHeaderShow,
  defaultContactHeaderOrders,
  showTableColumn,
  defaultJobHeaderOrders,
  jobHeaderShow,
  defaultCandidateHeaderOrders,
  candidateHeaderShow,
  defaultSubmHeaderOrders,
  submCandidateHeaderShow,
  ownershipChatOption,
  FunnelChartOptions,
  ColumnChartsObject,
  totalModules,
};
