import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Heading, Text, useColorModeValue } from "@chakra-ui/react";
import Container from "react-bootstrap/Container";
import { Modal } from "react-bootstrap";
import "../../views/Dashboard/org.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Tooltip } from "@chakra-ui/react";
import "react-phone-input-2/lib/style.css";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import NotFound from "assets/svg/not-found.svg";
import Loader from "../../views/Dashboard/Loader";

const AccountPreviewModel = ({
  accountPreview,
  handleSubmissionPreviewClose,
  objectURL,
  displayAccount,
  selectedType,
  type,
}) => {

  return (
    <>
      <div className="model-box-view">
        <Modal
          show={accountPreview}
          onHide={handleSubmissionPreviewClose}
          backdrop="static"
          keyboard={false}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title className="usertitle">Resume Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col lg={8} md={6} sm={6}>
                  {!objectURL ? (
                    <>
                      <div className="resume_ld">
                        <Loader />
                      </div>
                    </>
                  ) : objectURL === "no-resume" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={NotFound}
                        alt="NotFound"
                        style={{ width: "16rem" }}
                      />
                      <p>No Resume Found! Please try again....</p>
                    </div>
                  ) : (
                    <div
                      style={{
                        borderRight: "1px solid rgb(222, 226, 230)",
                        paddingRight: "18px",
                      }}
                    >
                      <iframe src={objectURL} width="100%" height="800px" />
                    </div>
                  )}
                </Col>
                <Col lg={4} md={6} sm={6}>
                  <div className="resume_pr">
                    <div>
                      <Heading
                        size="md"
                        fontWeight="bold"
                        letterSpacing="tight"
                      >
                        {type && type == "user" ? (
                          <>
                            {!displayAccount?.firstName
                              ? "N/A"
                              : `${displayAccount?.firstName} ${displayAccount?.lastName}`  }
                          </>
                        ) : (
                          <>
                            {!displayAccount?.accountName
                              ? "N/A"
                              : displayAccount?.accountName}{" "}
                          </>
                        )}
                      </Heading>
                      <Text color={useColorModeValue("gray.600", "gray.400")}>
                        <CallOutlinedIcon style={{ fontSize: "medium" }} />{" "}
                        {!displayAccount?.phone ? "N/A" : displayAccount?.phone}
                      </Text>
                      <Text color={useColorModeValue("gray.600", "gray.400")}>
                        <EmailOutlinedIcon style={{ fontSize: "medium" }} />{" "}
                        {!displayAccount?.email ? "N/A" : displayAccount?.email}
                      </Text>
                    </div>
                    <div>
                      <Tooltip
                        hasArrow
                        label="Download Document"
                        bg="gray.300"
                        color="black"
                      >
                        <a href={objectURL} download>
                          <button className="btn bg-white border rounded mb-2 text-break">
                            {type && type == "user" ? (
                              <>
                                {!displayAccount?.userDetail
                              ? "N/A"
                              : displayAccount?.userDetail &&
                                displayAccount?.userDetail[selectedType] && (
                                  <span>
                                    {displayAccount.userDetail[selectedType]
                                      .split("/")
                                      .pop()}
                                    <VerticalAlignBottomIcon
                                      style={{ color: "grey" }}
                                    />
                                  </span>
                                )}
                              </>
                            ) : (
                              <>
                            {!displayAccount?.companydetail
                              ? "N/A"
                              : displayAccount?.companydetail &&
                                displayAccount?.companydetail[selectedType] && (
                                  <span>
                                    {displayAccount.companydetail[selectedType]
                                      .split("/")
                                      .pop()}
                                    <VerticalAlignBottomIcon
                                      style={{ color: "grey" }}
                                    />
                                  </span>
                                )}
                              </>
                            )}
                          </button>
                        </a>
                      </Tooltip>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <button className="cancel" onClick={handleSubmissionPreviewClose}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AccountPreviewModel;
