import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useParams } from "react-router";

const NoteSearch = ({
  getCandidatedata,
  setDisplayCandidates,
  displaycandidates,
  Searchicon,
}) => {
  let { id } = useParams();
  const [noteAllSearch, setNoteAllSearch] = useState("");
  const noteUniversalSearch = (string = "") => {
    if (string.length !== 0) {
      var filterNote = displaycandidates?.filter((candidate) => {
        const hasMatchingNote = candidate?.notes?.some(
          (note) =>
            note?.candidateNote &&
            note?.candidateNote.toLowerCase().includes(string.toLowerCase())
        );

        return hasMatchingNote;
      });
      setDisplayCandidates(filterNote);
    } else {
      console.log("inside else part ");
      id ? getCandidatedata(id) : getCandidatedata();
    }
  };
  return (
    <>
      <Col lg={4}>
        <InputGroup mb={[2, 2, 2, 0, , 0]}>
          <InputLeftElement
            pointerEvents="none"
            children={<img src={Searchicon} width="18px" height="18px" />}
          />
          <Input
            type="text"
            id="searchAllfields"
            placeholder="Search Note ......"
            value={noteAllSearch}
            onChange={(e) => {
              setNoteAllSearch(e.target.value);
              noteUniversalSearch(e.target.value);
            }}
            style={{
              height: "37px",
              padding: "9px",
              paddingLeft: "2.6rem",
              color: "#7B9AAA",
              fontFamily: "Roboto",
              fontWeight: "500",
              lineHeight: "19px",
              fontSize: "15px",
            }}
          />
        </InputGroup>
      </Col>
    </>
  );
};

export default NoteSearch;
