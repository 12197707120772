import {
  Flex,
  Link,
  Spinner,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  useColorModeValue,
  Tooltip,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import IconBox from "components/Icons/IconBox";
import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";

const CountCard = ({
  label,
  path,
  loading,
  numberCount,
  icon,
  text,
  tooltipData,
}) => {
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <React.Fragment>
        <Card minH="125px">
      <Tooltip label={tooltipData?.join(",")}>
          <Flex direction="column">
            <Flex
              flexDirection="row"
              align="center"
              justify="center"
              w="100%"
              mb="25px"
            >
              <Stat me="auto">
                <StatLabel
                  fontSize="xs"
                  color="gray.400"
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                  {label}
                </StatLabel>
                <Flex>
                  <StatNumber fontSize="lg" color={textColor} fontWeight="bold">
                    {path ? (
                      <Link to={path} as={ReactRouterLink}>
                        {loading ? <Spinner /> : <>{numberCount}</>}
                      </Link>
                    ) : loading ? (
                      <Spinner />
                    ) : (
                      <Text cursor={"pointer"} mx={1}>
                        {numberCount}
                      </Text>
                    )}
                  </StatNumber>
                </Flex>
              </Stat>
              <Link to={path}>
                <IconBox
                  borderRadius="50%"
                  as="box"
                  h={"35px"}
                  w={"35px"}
                  style={{ cursor: "pointer" }}
                  className="bg_color"
                >
                  {icon}
                </IconBox>
              </Link>
            </Flex>
            <Text color="gray.400" fontSize="sm">
              {path ? (
                <Text as="span" color="green.400" mx={1} fontWeight="bold">
                  <Link to={path} as={ReactRouterLink}>
                    {numberCount}
                  </Link>
                </Text>
              ) : (
                <Text
                  as="span"
                  cursor={"pointer"}
                  mx={1}
                  color="green.400"
                  fontWeight="bold"
                >
                  {numberCount}
                </Text>
              )}
              {text}
            </Text>
          </Flex>
      </Tooltip>
        </Card>
    </React.Fragment>
  );
};

export default CountCard;
