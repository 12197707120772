import { Box, Button, FormControl, Input, Textarea } from "@chakra-ui/react";
import "./org.css";

import axios from "axios";
import { useFormik } from "formik";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import config from "../../config";
import { userActivity } from "Utils";
import { useCookies } from "react-cookie";

const ComposeMailForm = ({
  ViewSendMail,
  handleSendMailClose,
  SetToemail,
  SetCcemail,
  SetBccemail,
  SetSubject,
  SetMailMessage,
  checked,
  endPoint,
}) => {
  const [cookie] = useCookies(["role", "id", "token"]);
  const candidate_ownerName = localStorage?.getItem("candidate_ownerName");
  const userId = cookie.id;
  const userRole = cookie?.role;

  const validationSchema = Yup.object().shape({
    toemail: Yup.string().required("To Field Is Required"),
    ccemail: Yup.string().required("Subject Field Is Required"),
    bccemail: Yup.string().required("Subject Field Is Required"),
    subject: Yup.string().required("Subject Field Is Required"),
    mailMessage: Yup.string().required("Mail Message Is Required"),
  });

  const formik = useFormik({
    initialValues: {
      toemail: "",
      ccemail: "",
      bccemail: "",
      subject: "",
      mailMessage: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (item) => {
      const check = checked?.map((val) => val?.email);
      check.push(item.toemail);
      const response = await axios
        .post(`${config?.apiUrl}${endPoint}`, {
          email: check,
          ccemail: item.ccemail,
          bccemail: item.bccemail,
          subject: item.subject,
          message: item.mailMessage,
        })
        .then((response) => {
          toast.success(response.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
          const activityOn = `Compose mail send `;
          const activityDo = `Mail send`;
          const component = "candidate";
          userActivity(
            candidate_ownerName,
            userId,
            component,
            activityDo,
            activityOn,
            userRole
          );
          
          formik.resetForm();
          handleSendMailClose();
          SetToemail("");
          SetCcemail("");
          SetBccemail("");
          SetSubject("");
          SetMailMessage("");
        })
        .catch((err) => {
          console.log("Err form compose mail send",err)
          toast.error("Please Check details", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    },
  });

  const getEmailMessages = async () => {
    try {
      const endpointMapping = {
        "/api/SelectCandidate_EmailSend": "candidate",
        "/api/SelectClient_EmailSend": "client",
        "/api/SelectJob_EmailSend": "job",
        "/api/SelectContact_EmailSend": "contactPerson",
      };

      const response = await axios.get(
        `${config?.apiUrl}/api/getEmailMessages`
      );

      if (response.status === 200) {
        const emailType = endpointMapping[endPoint];

        if (emailType) {
          const emailMessage = response.data.find(
            (message) => message.emailType === emailType
          );

          if (emailMessage) {
            formik.setValues({
              ...formik.values,
              mailMessage: emailMessage.emailMessage,
            });
          } else {
            console.log(`No email message found for ${emailType}.`);
          }
        } else {
          console.log("Endpoint not mapped to any emailType.");
        }
      } else {
        toast.error("Failed to fetch email messages. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("An unexpected error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    getEmailMessages();
  }, [ViewSendMail]);

  return (
    <>
      <div className="model-box-view">
        <Modal
          show={ViewSendMail}
          onHide={() => {
            handleSendMailClose();
            formik.resetForm();
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title className="usertitle">Compose Mail</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={formik.handleSubmit}>
              <FormControl>
                <Box style={{ marginBottom: "24px" }}>
                  <label>To</label>
                  <Input
                    variant="auth"
                    fontSize="sm"
                    type="text"
                    placeholder="To"
                    size="lg"
                    name="toemail"
                    value={formik.values.toemail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{
                      height: "37px",
                      border:
                        formik.touched.toemail && formik.errors.toemail
                          ? "1px solid red"
                          : "",
                    }}
                  />
                  {formik.touched.toemail && formik.errors.toemail && (
                    <div style={{ color: "red", fontSize: "14px" }}>
                      {formik.errors.toemail}
                    </div>
                  )}
                </Box>
                <Box style={{ marginBottom: "24px" }}>
                  <label>CC</label>
                  <Input
                    variant="auth"
                    fontSize="sm"
                    type="text"
                    placeholder="CC"
                    size="lg"
                    name="ccemail"
                    value={formik.values.ccemail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{
                      height: "37px",
                      border:
                        formik.touched.ccemail && formik.errors.ccemail
                          ? "1px solid red"
                          : "",
                    }}
                  />
                  {formik.touched.ccemail && formik.errors.ccemail && (
                    <div style={{ color: "red", fontSize: "14px" }}>
                      {formik.errors.ccemail}
                    </div>
                  )}
                </Box>
                <Box style={{ marginBottom: "24px" }}>
                  <label>BCC</label>
                  <Input
                    variant="auth"
                    fontSize="sm"
                    type="text"
                    placeholder="BCC"
                    size="lg"
                    name="bccemail"
                    value={formik.values.bccemail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{
                      height: "37px",
                      border:
                        formik.touched.bccemail && formik.errors.bccemail
                          ? "1px solid red"
                          : "",
                    }}
                  />
                  {formik.touched.bccemail && formik.errors.bccemail && (
                    <div style={{ color: "red", fontSize: "14px" }}>
                      {formik.errors.bccemail}
                    </div>
                  )}
                </Box>
                <Box style={{ marginBottom: "24px" }}>
                  <label>Subject</label>
                  <Input
                    variant="auth"
                    fontSize="sm"
                    type="text"
                    placeholder="Subject"
                    size="lg"
                    name="subject"
                    value={formik.values.subject}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{
                      height: "37px",
                      border:
                        formik.touched.subject && formik.errors.subject
                          ? "1px solid red"
                          : "",
                    }}
                  />
                  {formik.touched.subject && formik.errors.subject && (
                    <div style={{ color: "red", fontSize: "14px" }}>
                      {formik.errors.subject}
                    </div>
                  )}
                </Box>
                <Box style={{ marginBottom: "24px" }}>
                  <Textarea
                    variant="auth"
                    fontSize="sm"
                    type="text"
                    value={formik.values.mailMessage}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Type Here..."
                    disabled
                    size="lg"
                    style={{
                      border: "1px solid #ececec",
                      height: "163px",
                      border:
                        formik.touched.mailMessage && formik.errors.mailMessage
                          ? "1px solid red"
                          : "",
                    }}
                    name="mailMessage"
                  />
                  {formik.touched.mailMessage && formik.errors.mailMessage && (
                    <div style={{ color: "red", fontSize: "14px" }}>
                      {formik.errors.mailMessage}
                    </div>
                  )}
                </Box>
              </FormControl>
              <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  className="cancel"
                  type="button"
                  onClick={() => {
                    handleSendMailClose();
                    formik.resetForm();
                  }}
                >
                  Cancel
                </Button>
                <Button className="btn_bg_color" type="submit">
                  Send
                </Button>
              </Modal.Footer>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ComposeMailForm;
