import Dashboard from "views/Dashboard/Dashboard.js";

import { HomeIcon } from "components/Icons/Icons";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import GroupIcon from "@mui/icons-material/Group";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import DescriptionIcon from "@mui/icons-material/Description";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import TaskIcon from "@mui/icons-material/Task";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
// import InsertChartIcon from "@mui/icons-material/InsertChart";
import SettingsIcon from "@mui/icons-material/Settings";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import UserRole from "views/Dashboard/UserRole";
import { lazy } from "react";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
// import Rejected from "views/Dashboard/Rejected";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
// import BackoutCandidate from "views/Dashboard/BackoutCandidate";

const BackoutCandidate = lazy(
  () => import("./views/Dashboard/BackoutCandidate")
);
const Rejected = lazy(() => import("./views/Dashboard/Rejected"));
const Accounts = lazy(() => import("./views/Dashboard/Accounts"));
const AccountContact = lazy(() => import("./views/Dashboard/AccountContact"));
const Jobpost = lazy(() => import("./views/Dashboard/Jobpost"));
const Candidate = lazy(() => import("./views/Dashboard/Candidate"));
const PendingCandidate = lazy(
  () => import("./views/Dashboard/PendingCandidate")
);
const Submission = lazy(() => import("./views/Dashboard/Submission"));
const QAcheckCandidate = lazy(
  () => import("./views/Dashboard/QAcheckCandidate")
);
const Interviewschedule = lazy(
  () => import("./views/Dashboard/Interviewschedule")
);
const Interviewattained = lazy(
  () => import("./views/Dashboard/Interviewattained")
);
const InterviewComplete = lazy(
  () => import("./views/Dashboard/InterviewComplete")
);
const SelectedCandidate = lazy(
  () => import("./views/Dashboard/SelectedCandidate")
);
const JoinedCandidate = lazy(() => import("./views/Dashboard/JoinedCandidate"));
// const Reports = lazy(() => import("./views/Dashboard/Reports"));
const Settings = lazy(() => import("./views/Dashboard/Settings"));
const Simpleform = lazy(() => import("./views/Dashboard/Simpleform"));

const Role = localStorage.getItem("role");

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/accounts",
    name: "Clients",
    icon: <CorporateFareIcon />,
    secondaryNavbar: true,
    component: Accounts,
    layout: "/admin",
  },
  {
    path: "/contact",
    name: "Contacts",
    icon: <PermContactCalendarIcon />,
    secondaryNavbar: true,
    component: AccountContact,
    layout: "/admin",
  },
  {
    path: "/jobpost",
    name: "Jobs",
    icon: <WorkOutlineIcon fontSize="small" />,
    secondaryNavbar: true,
    component: Jobpost,
    layout: "/admin",
  },
  {
    path: "/candidate",
    name: "Candidates",
    icon: <GroupIcon />,
    secondaryNavbar: true,
    component: Candidate,
    layout: "/admin",
  },
  {
    path: "/pendingcandidate",
    name: "Pending Candidates",
    icon: <PendingActionsIcon />,
    secondaryNavbar: true,
    component: PendingCandidate,
    layout: "/admin",
  },
  {
    path: "/qacheckcandidate",
    name: "QA check",
    icon: <QuestionAnswerIcon />,
    secondaryNavbar: true,
    component: QAcheckCandidate,
    layout: "/admin",
  },
  {
    path: "/submission",
    name: "Submission",
    icon: <DescriptionIcon />,
    secondaryNavbar: true,
    component: Submission,
    layout: "/admin",
  },
  {
    path: "/interviewschedule",
    name: "Interview Schedule",
    icon: <EventAvailableIcon />,
    secondaryNavbar: true,
    component: Interviewschedule,
    layout: "/admin",
  },
  {
    path: "/interviewattained",
    name: "Interview Attained",
    icon: <WorkHistoryIcon />,
    secondaryNavbar: true,
    component: Interviewattained,
    layout: "/admin",
  },
  {
    path: "/interviewcomplete",
    name: "Interview Complete",
    icon: <TaskIcon />,
    secondaryNavbar: true,
    component: InterviewComplete,
    layout: "/admin",
  },
  {
    path: "/selected",
    name: "Selected",
    icon: <HowToRegIcon />,
    secondaryNavbar: true,
    component: SelectedCandidate,
    layout: "/admin",
  },
  {
    path: "/joined",
    name: "Joined",
    icon: <PersonAddAlt1Icon />,
    secondaryNavbar: true,
    component: JoinedCandidate,
    layout: "/admin",
  },
  {
    path: "/rejected",
    name: "Rejected",
    icon: <ThumbDownIcon />,
    secondaryNavbar: true,
    component: Rejected,
    layout: "/admin",
  },
  {
    path: "/backoutcandidate",
    name: "Backout",
    icon: <NotInterestedIcon />,
    secondaryNavbar: true,
    component: BackoutCandidate,
    layout: "/admin",
  },
  // {
  //   path: "/report",
  //   name: "Reports",
  //   icon: <InsertChartIcon />,
  //   secondaryNavbar: true,
  //   component: Reports,
  //   layout: "/admin",
  // },
  {
    path: "/simpleform",
    name: "Form",
    icon: <PlaylistAddIcon />,
    secondaryNavbar: true,
    component: Simpleform,
    layout: "/admin",
  },
  {
    path: "/useractivity",
    name: "User Activity",
    icon: <ManageAccountsIcon />,
    secondaryNavbar: true,
    component: UserRole,
    layout: "/admin",
  },
  {
    path: "/setting",
    name: "Setting",
    icon: <SettingsIcon />,
    secondaryNavbar: true,
    component: Settings,
    layout: "/admin",
  },
];

// if (Role === "Recruiter" || Role === "Team Lead") {
//   dashRoutes = dashRoutes.filter(
//     (route) =>
//       route.path !== "/accounts" &&
//       route.path !== "/contact" &&
//       route.path !== "/useractivity"
//   );
// }

// if (Role === "Admin" || Role === "Account Manager") {
//   dashRoutes.push(
//     // {
//     //   path: "/accounts",
//     //   name: "Clients",
//     //   icon: <CorporateFareIcon />,
//     //   secondaryNavbar: true,
//     //   component: Accounts,
//     //   layout: "/admin",
//     // },
//     // {
//     //   path: "/contact",
//     //   name: "Contacts",
//     //   icon: <PermContactCalendarIcon />,
//     //   secondaryNavbar: true,
//     //   component: AccountContact,
//     //   layout: "/admin",
//     // },
//     // {
//     //   path: "/setting",
//     //   name: "Setting",
//     //   icon: <SettingsIcon />,
//     //   secondaryNavbar: true,
//     //   component: Settings,
//     //   layout: "/admin",
//     // },
//     // {
//     //   path: "/simpleform",
//     //   name: "Form",
//     //   icon: <PlaylistAddIcon />,
//     //   secondaryNavbar: true,
//     //   component: Simpleform,
//     //   layout: "/admin",
//     // },

//   );
// }

export default dashRoutes;
