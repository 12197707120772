import {
  Box,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Checkbox,
  Select,
  Button,
} from "@chakra-ui/react";
import config from "../../../config";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const SelectUserRoles = ({ selectedRole }) => {
  const [moduleData, setModuleData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const Module = [
    {
      Module: "Jobs",
      Add: false,
      View: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      Edit: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },

      Delete: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      component: true,
      exportExcel: false,
    },
    {
      Module: "candidate",
      Add: false,
      View: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      Edit: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },

      Delete: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      component: true,
      exportExcel: false,
    },
    {
      Module: "submission",
      Add: false,
      View: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      Edit: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },

      Delete: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      component: true,
      exportExcel: false,
    },
    {
      Module: "client",
      Add: false,
      View: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      Edit: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },

      Delete: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      component: false,
      exportExcel: false,
    },
    {
      Module: "contact",
      Add: false,
      View: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      Edit: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },

      Delete: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      component: false,
      exportExcel: false,
    },
    {
      Module: "simpleform",
      Add: false,
      View: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      Edit: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },

      Delete: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      component: false,
      exportExcel: false,
    },
    {
      Module: "setting",
      Add: false,
      View: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      Edit: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },

      Delete: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      component: true,
      exportExcel: false,
    },
    {
      Module: "qacheckcandidate",
      Add: false,
      View: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      Edit: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },

      Delete: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      component: true,
      exportExcel: false,
    },
    {
      Module: "interviewschedule",
      Add: false,
      View: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      Edit: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },

      Delete: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      component: true,
      exportExcel: false,
    },
    {
      Module: "interviewattained",
      Add: false,
      View: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      Edit: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },

      Delete: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      component: true,
      exportExcel: false,
    },
    {
      Module: "interviewcomplete",
      Add: false,
      View: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      Edit: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },

      Delete: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      component: true,
      exportExcel: false,
    },
    {
      Module: "selected",
      Add: false,
      View: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      Edit: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },

      Delete: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      component: true,
      exportExcel: false,
    },
    {
      Module: "joined",
      Add: false,
      View: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      Edit: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },

      Delete: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      component: true,
      exportExcel: false,
    },
    {
      Module: "rejected",
      Add: false,
      View: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      Edit: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },

      Delete: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      component: true,
      exportExcel: false,
    },
    {
      Module: "backoutcandidate",
      Add: false,
      View: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      Edit: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },

      Delete: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      component: true,
      exportExcel: false,
    },
    {
      Module: "report",
      Add: false,
      View: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      Edit: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      Delete: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      component: false,
      exportExcel: false,
    },
    {
      Module: "useractivity",
      Add: false,
      View: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      Edit: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      Delete: {
        none: "none",
        allrecords: "allrecords",
        ownrecords: "ownrecords",
        ownandteamrecords: "ownandteamrecords",
      },
      component: false,
    },
  ];

  const fetchModuleData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${config.apiUrl}/api/getpermissions/${selectedRole}`
      );
      setModuleData(response.data.modules);
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        await postDataOfPermission();
        setModuleData([]);
      } else {
        console.error("Error while fetching module data:", error);
      }
      setIsLoading(false);
    }
  };

  const postDataOfPermission = async () => {
    try {
      const response = await axios.post(
        `${config?.apiUrl}/api/addpermissions`,
        {
          role: selectedRole,
          modules: Module.map((item, index) => ({
            module: item.Module,
            permissions: {
              add: item.Add,
              view: "none",
              edit: "none",
              delete: "none",
              component: item?.component,
              exportExcel: false,
            },
          })),
        }
      );
      fetchModuleData();
    } catch (error) {
      console.error("Error while posting data:", error);
    }
  };

  useEffect(() => {
    fetchModuleData();
  }, [selectedRole]);

  const handlePermissionChange = async (moduleIndex, field, value) => {
    const updatedModuleData = [...moduleData];
    updatedModuleData[moduleIndex].permissions[field] = value;
    setModuleData(updatedModuleData);
  };

  const handleSavePermissions = async () => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        `${config.apiUrl}/api/updatepermissions/${selectedRole}`,
        { modules: moduleData }
      );
      console.log(response.data);
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      fetchModuleData();
      setIsLoading(false);
    } catch (error) {
      console.error("Error while updating permissions:", error);
      setIsLoading(false);
    }
  };
  const getModuleName = (moduleName) => {
    switch (moduleName) {
      case "Jobs":
        return "Jobs";
      case "candidate":
        return "Candidate";
      case "submission":
        return "Submission";
      case "client":
        return "Client";
      case "contact":
        return "Contact";
      case "simpleform":
        return "Simple Form";
      case "setting":
        return "Setting";
      case "qacheckcandidate":
        return "QA Check Candidate";
      case "interviewschedule":
        return "Interview Schedule";
      case "interviewattained":
        return "Interview Attained";
      case "interviewcomplete":
        return "Interview Complete";
      case "selected":
        return "Selected";
      case "joined":
        return "Joined";
      case "rejected":
        return "Rejected";
      case "backoutcandidate":
        return "Backout Candidate";
      case "report":
        return "Report";
      case "useractivity":
        return "User Activity";
      default:
        return moduleName;
    }
  };

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <Box>
          <Box mb={5}>
            <span
              style={{ fontSize: "15px", fontWeight: "bold", color: "#000" }}
            >
              {"ATS >"} {selectedRole}{" "}
            </span>
          </Box>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Module</Th>
                  <Th>Module show</Th>
                  <Th>Excel Download</Th>
                  <Th>Add</Th>
                  <Th>View</Th>
                  <Th>Edit</Th>
                  <Th>Delete</Th>
                </Tr>
              </Thead>
              <Tbody>
                {moduleData.map((item, index) => (
                  <Tr key={index}>
                    <Td>{getModuleName(item?.module)}</Td>
                    <Td>
                      {" "}
                      <Checkbox
                        ml={3}
                        // colorScheme={"var(--main-bg-color)"}
                        defaultChecked={item?.permissions?.component}
                        onChange={(e) =>
                          handlePermissionChange(
                            index,
                            "component",
                            e.target.checked
                          )
                        }
                      />
                    </Td>
                    <Td>
                      {" "}
                      <Checkbox
                        ml={3}
                        // colorScheme={"var(--main-bg-color)"}
                        defaultChecked={item?.permissions?.exportExcel}
                        onChange={(e) =>
                          handlePermissionChange(
                            index,
                            "exportExcel",
                            e.target.checked
                          )
                        }
                      />
                    </Td>
                    <Td>
                      <Checkbox
                        // colorScheme={"var(--main-bg-color)"}
                        defaultChecked={item?.permissions?.add}
                        onChange={(e) =>
                          handlePermissionChange(index, "add", e.target.checked)
                        }
                      />
                    </Td>
                    <Td>
                      <Select
                        minW={"150px"}
                        defaultValue={item?.permissions?.view}
                        onChange={(e) =>
                          handlePermissionChange(index, "view", e.target.value)
                        }
                      >
                        {[
                          "none",
                          "allrecords",
                          "ownrecords",
                          "ownandteamrecords",
                        ].map((value) => {
                          let optionText =
                            value.charAt(0).toUpperCase() + value.slice(1);
                          if (value === "allrecords")
                            optionText = "All Records";
                          else if (value === "ownrecords")
                            optionText = "Own Records";
                          else if (value === "ownandteamrecords")
                            optionText = "Own and Team Records";

                          return (
                            <option key={value} value={value}>
                              {optionText}
                            </option>
                          );
                        })}
                      </Select>
                    </Td>
                    <Td>
                      <Select
                        minW={"150px"}
                        defaultValue={item?.permissions?.edit}
                        onChange={(e) =>
                          handlePermissionChange(index, "edit", e.target.value)
                        }
                      >
                        {[
                          "none",
                          "allrecords",
                          "ownrecords",
                          "ownandteamrecords",
                        ].map((value) => {
                          // Modifying option text for better view
                          let optionText =
                            value.charAt(0).toUpperCase() + value.slice(1);
                          if (value === "allrecords")
                            optionText = "All Records";
                          else if (value === "ownrecords")
                            optionText = "Own Records";
                          else if (value === "ownandteamrecords")
                            optionText = "Own and Team Records";

                          return (
                            <option key={value} value={value}>
                              {optionText}
                            </option>
                          );
                        })}
                      </Select>
                    </Td>
                    <Td>
                      <Select
                        minW={"150px"}
                        defaultValue={item?.permissions?.delete}
                        onChange={(e) =>
                          handlePermissionChange(
                            index,
                            "delete",
                            e.target.value
                          )
                        }
                      >
                        {[
                          "none",
                          "allrecords",
                          "ownrecords",
                          "ownandteamrecords",
                        ].map((value) => {
                          let optionText =
                            value.charAt(0).toUpperCase() + value.slice(1);
                          if (value === "allrecords")
                            optionText = "All Records";
                          else if (value === "ownrecords")
                            optionText = "Own Records";
                          else if (value === "ownandteamrecords")
                            optionText = "Own and Team Records";

                          return (
                            <option key={value} value={value}>
                              {optionText}
                            </option>
                          );
                        })}
                      </Select>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Button
            colorScheme=""
            bg={"var(--main-bg-color)"}
            isLoading={isLoading}
            mt={4}
            onClick={handleSavePermissions}
          >
            Update Permissions
          </Button>
        </Box>
      )}
    </>
  );
};

export default SelectUserRoles;
