import UserActivity from "components/Commoncomponent/Useractivity/UserActivity";
import React from "react";

const UserRole = () => {
  return (
    <>
      <UserActivity />
    </>
  );
};

export default UserRole;
