import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Menu, MenuItem, Sidebar as ProSidebar } from "react-pro-sidebar";

import Logo from "assets/img/logo.png";
import axios from "axios";
import { HSeparator } from "components/Separator/Separator";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Link, NavLink, useLocation } from "react-router-dom";
import config from "../../config";
import { useSelector } from "react-redux";

// FUNCTIONS

function Sidebar(props) {
  // to check for active links and opened collapses
  const { collapsed } = useSelector((state) => state.sidebar);
  let location = useLocation();
  // this is for the rest of the collapses
  const [state, setState] = React.useState({});
  const mainPanel = React.useRef();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    // Chakra Color Mode
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.category) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <>
            {document.documentElement.dir === "rtl" ? prop.rtlName : prop.name}
            {createLinks(prop.views)}
          </>
        );
      }
      return (
        <NavLink to={prop.layout + prop.path} key={key}>
          {activeRoute(prop.layout + prop.path) === "active" ? (
            collapsed ? (
              <Tooltip
                label={prop.name}
                aria-label={`Tooltip for ${prop.name}`}
              >
                <div>
                  <MenuItem
                    style={{
                      background: "#023F61",
                      color: "white",
                    }}
                    icon={<div style={{ fontSize: "25px" }}>{prop.icon}</div>}
                  >
                    {prop.name}
                  </MenuItem>
                </div>
              </Tooltip>
            ) : (
              <div>
                <MenuItem
                  style={{
                    background: "#023F61",
                    color: "white",
                  }}
                  icon={<div style={{ fontSize: "25px" }}>{prop.icon}</div>}
                >
                  {prop.name}
                </MenuItem>
              </div>
            )
          ) : collapsed ? (
            <Tooltip label={prop.name} aria-label={`Tooltip for ${prop.name}`}>
              <MenuItem
                icon={<div style={{ fontSize: "25px" }}>{prop.icon}</div>}
              >
                {" "}
                {prop.name}{" "}
              </MenuItem>
            </Tooltip>
          ) : (
            <MenuItem
              icon={<div style={{ fontSize: "25px" }}>{prop.icon}</div>}
            >
              {" "}
              {prop.name}{" "}
            </MenuItem>
          )}
        </NavLink>
      );
    });
  };
  const { logo, routes } = props;
  const [cookie, setCookie] = useCookies(["id", "token", "role"]);
  const [moduleData, setModuleData] = useState([]);

  const fetchModuleData = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/api/getpermissions/${cookie.role}`
      );
      const modules = response.data.modules;

      const moduleData = {};
      modules.forEach((module) => {
        moduleData[module.module] = module?.permissions.component;
      });
      setModuleData(moduleData);
      console.log(moduleData);
    } catch (error) {
      console.error("Error while fetching module data:", error);
    }
  };

  useEffect(() => {
    if (cookie?.role && cookie?.role !== "Admin") {
      fetchModuleData();
    }
  }, [cookie?.role]);
  const [filterRoute, setFilterRoutes] = useState([]);
  const filterRoutes = () => {
    let filteredRoutes = [...routes]; // Copying the original routes array

    if (moduleData.submission == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/submission")
      );
    }
    if (moduleData.Jobs == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/jobpost")
      );
    }
    if (moduleData.candidate == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/candidate")
      );
    }
    if (moduleData.candidate == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/pendingcandidate")
      );
    }
    if (moduleData.client == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/accounts")
      );
    }
    if (moduleData.contact == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/contact")
      );
    }
    if (moduleData.interviewattained == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/interviewattained")
      );
    }
    if (moduleData.interviewcomplete == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/interviewcomplete")
      );
    }
    if (moduleData.interviewschedule == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/interviewschedule")
      );
    }
    if (moduleData.joined == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/joined")
      );
    }
    if (moduleData.qacheckcandidate == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/qacheckcandidate")
      );
    }
    if (moduleData.selected == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/selected")
      );
    }
    if (moduleData.setting == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/setting")
      );
    }
    if (moduleData.simpleform == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/simpleform")
      );
    }
    if (moduleData.useractivity == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/useractivity")
      );
    }
    if (moduleData.rejected == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/rejected")
      );
    }
    if (moduleData.backoutcandidate == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/backoutcandidate")
      );
    }
    setFilterRoutes(filteredRoutes);
    // return filteredRoutes;
  };
  useEffect(() => {
    cookie.role !== "Admin" && filterRoutes();
    cookie.role == "Admin" && setFilterRoutes(routes);
  }, [moduleData, routes]);

  var links = <>{createLinks(filterRoute)}</>;
  //  BRAND
  //  Chakra Color Mode
  let sidebarBg = useColorModeValue("white", "navy.800");
  let sidebarRadius = "20px";
  var brand = (
    <Box pt={"25px"} mb="12px">
      {logo}
      <HSeparator my="26px" />
    </Box>
  );

  // SIDEBAR
  return (
    <Box ref={mainPanel}>
      {/* <Box display={{ sm: "none", xl: "block" }} position="fixed">
        <Box
          bg={sidebarBg}
          w="260px"
          maxW="260px"
          ms={{
            sm: "16px",
          }}
          my={{
            sm: "16px",
          }}
          h="calc(100vh - 32px)"
          ps="20px"
          pe="20px"
          borderRadius={sidebarRadius}
        >
          <Scrollbars
            autoHide
            renderTrackVertical={
              document.documentElement.dir === "rtl"
                ? renderTrackRTL
                : renderTrack
            }
            renderThumbVertical={useColorModeValue(
              renderThumbLight,
              renderThumbDark
            )}
            renderView={
              document.documentElement.dir === "rtl"
                ? renderViewRTL
                : renderView
            }
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "1rem",
              }}
            >
              <Link to="/admin/dashboard">
                <img src={Logo} alt="Logo" style={{ width: "7.5rem" }} />
              </Link>
            </Box>
            <HSeparator my="26px" />
            <Stack direction="column" mb="40px">
              <Box>{links}</Box>
            </Stack>
          </Scrollbars>
        </Box>
      </Box> */}
      <ProSidebar
        collapsed={collapsed}
        style={{ height: "100vh", position: "fixed", backgroundColor: "white" }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "1rem",
          }}
        >
          <Link to="/admin/dashboard">
            <img
              src={Logo}
              alt="Logo"
              style={{ width: "7.5rem", objectFit: "cover" }}
            />
          </Link>
        </Box>
        <Menu
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              if (level === 0) {
                return {
                  backgroundColor: active ? "#023F61" : undefined,
                  color: active ? "#fff" : "#023F61",
                  "&:hover": {
                    background: "#023F61",
                    color: "white",
                  },
                };
              }
            },
          }}
        >
          {links}
        </Menu>
      </ProSidebar>
    </Box>
  );
}

// FUNCTIONS

export function SidebarResponsive(props) {
  // to check for active links and opened collapses
  let location = useLocation();
  const { logo, routes, colorMode, hamburgerColor, ...rest } = props;

  // this is for the rest of the collapses
  const [state, setState] = React.useState({});
  const mainPanel = React.useRef();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };
  // Chakra Color Mode
  let activeColor = useColorModeValue("gray.700", "white");

  let sidebarBackgroundColor = useColorModeValue("white", "navy.800");
  const [cookie, setCookie] = useCookies(["id", "token", "role"]);
  const [moduleData, setModuleData] = useState([]);

  const fetchModuleData = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/api/getpermissions/${cookie.role}`
      );
      const modules = response.data.modules;

      const moduleData = {};
      modules.forEach((module) => {
        moduleData[module.module] = module?.permissions.component;
      });
      setModuleData(moduleData);
    } catch (error) {
      console.error("Error while fetching module data:", error);
    }
  };

  useEffect(() => {
    if (cookie?.role && cookie?.role !== "Admin") {
      fetchModuleData();
    }
  }, [cookie?.role]);
  const [filterRoute, setFilterRoutes] = useState([]);
  const filterRoutes = () => {
    let filteredRoutes = [...routes]; // Copying the original routes array

    if (moduleData.submission == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/submission")
      );
    }
    if (moduleData.Jobs == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/jobpost")
      );
    }
    if (moduleData.candidate == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/candidate")
      );
    }
    if (moduleData.candidate == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/pendingcandidate")
      );
    }
    if (moduleData.client == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/accounts")
      );
    }
    if (moduleData.contact == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/contact")
      );
    }
    if (moduleData.interviewattained == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/interviewattained")
      );
    }
    if (moduleData.interviewcomplete == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/interviewcomplete")
      );
    }
    if (moduleData.interviewschedule == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/interviewschedule")
      );
    }
    if (moduleData.joined == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/joined")
      );
    }
    if (moduleData.qacheckcandidate == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/qacheckcandidate")
      );
    }
    if (moduleData.selected == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/selected")
      );
    }
    if (moduleData.setting == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/setting")
      );
    }
    if (moduleData.simpleform == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/simpleform")
      );
    }
    if (moduleData.useractivity == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/useractivity")
      );
    }
    if (moduleData.rejected == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/rejected")
      );
    }
    if (moduleData.backoutcandidate == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/backoutcandidate")
      );
    }

    setFilterRoutes(filteredRoutes);
  };

  useEffect(() => {
    cookie.role !== "Admin" && filterRoutes();
    cookie.role == "Admin" && setFilterRoutes(routes);
  }, [moduleData, routes]);

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.category) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <>
            <Text
              color={activeColor}
              fontWeight="bold"
              mb={{
                xl: "6px",
              }}
              mx="auto"
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              py="12px"
            >
              {document.documentElement.dir === "rtl"
                ? prop.rtlName
                : prop.name}
            </Text>
            {createLinks(prop.views)}
          </>
        );
      }
      return (
        <NavLink to={prop.layout + prop.path} key={key}>
          {activeRoute(prop.layout + prop.path) === "active" ? (
            <div className="activeiconbox">
              <div style={{ fontSize: "25px" }}>{prop.icon}</div>
              <div className="activeicontext">
                {document.documentElement.dir === "rtl"
                  ? prop.rtlName
                  : prop.name}
              </div>
            </div>
          ) : (
            <div className="iconbox">
              <div style={{ fontSize: "25px" }}>{prop.icon}</div>
              <div className="icontext">
                {document.documentElement.dir === "rtl"
                  ? prop.rtlName
                  : prop.name}
              </div>
            </div>
          )}
        </NavLink>
      );
    });
  };

  var links = <>{createLinks(filterRoute)}</>;

  //  BRAND

  var brand = (
    <Box pt={"35px"} mb="8px">
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "1rem",
        }}
      >
        <Link to="/admin/dashboard">
          <img src={Logo} alt="Logo" style={{ width: "5.5rem" }} />
        </Link>
      </Box>
      <HSeparator my="26px" />
    </Box>
  );

  // SIDEBAR
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  // Color variables
  return (
    <Flex
      display={{ sm: "flex", xl: "none" }}
      ref={mainPanel}
      alignItems="center"
    >
      <HamburgerIcon
        color={hamburgerColor}
        w="28px"
        h="28px"
        ref={btnRef}
        onClick={onOpen}
      />
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === "rtl" ? "right" : "left"}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          w="250px"
          maxW="250px"
          ms={{
            sm: "16px",
          }}
          my={{
            sm: "16px",
          }}
          borderRadius="16px"
          bg={sidebarBackgroundColor}
        >
          <DrawerCloseButton
            _focus={{ boxShadow: "none" }}
            _hover={{ boxShadow: "none" }}
          />
          <DrawerBody maxW="250px" px="1rem">
            <Box maxW="100%" h="100vh">
              <Box>{brand}</Box>
              <Stack direction="column" mb="40px">
                <Box>{links}</Box>
              </Stack>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}

export default Sidebar;
