import { Grid } from "@chakra-ui/react";
import "@uiw/react-markdown-preview/markdown.css";
import MarkdownEditor from "@uiw/react-md-editor";
import "@uiw/react-md-editor/markdown-editor.css";
import { useEffect, useState } from "react";

const JobDescriptionEditor = ({ formik }) => {
  const [editorState, setEditorState] = useState("");

  useEffect(() => {
    if (formik.values.jobdescription) {
      setEditorState(formik.values.jobdescription);
    } else {
      setEditorState("");
    }
  }, [formik.values.jobdescription]);

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    formik.setFieldValue("jobdescription", newEditorState);
  };

  return (
    <Grid>
      <MarkdownEditor
        value={editorState}
        onChange={onEditorStateChange}
        height={400}
      />
    </Grid>
  );
};

export default JobDescriptionEditor;
