import {
  Box,
  CloseButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@chakra-ui/react";
import NotFound from "assets/svg/not-found.svg";
import Searchicon from "assets/svg/search-icon.svg";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import "react-phone-input-2/lib/style.css";
import Loader from "views/Dashboard/Loader";
import PopoverSearchInput from "components/SearchJobModel/PopoverSearchInput";
import axios from "axios";
import config from "config";
import PopoverDropDownSearchInput from "./PopoverDropDownSearchInput";

const SearchCandidateModel = (props) => {
  const {
    searchCandidateModelShow,
    handleSearchCandidateClose,
    searchcandidate,
    setSearchcandidate,
    filterCandidateData,
    candidateModuleData,
    handleCandidateShow,
    parseFile,
    loading,
    candidates,
    setSubmission,
    submission,
    Setcandidatename,
    candidatename,
    handleSubmitSubmissionShow,
    rowsPerCandidateSubmissionPage,
    candidatesubmissionpage,
    handleChangeCandidateSubmissionPage,
    handleChangeRowsPerCandidateSubmissionPage,
    setSearchCandidateName,
    searchCandidateName,
    setSearchCandidateDesignation,
    searchCandidateDesignation,
    setSearchCandidateCity,
    searchCandidateCity,
    setCandidateSearchActiveField,
    candidateSearchActiveField,
    clearCandidateSearchFilter,
    jobpostview,
    setSearchCandidateOwnerName,
    searchCandidateOwnerName,
  } = props;

  const [activePopover, setActivePopover] = useState(null);

  const openPopover = (id) => {
    if (activePopover === id) {
      setActivePopover(null); // Toggle the current popover
    } else {
      setActivePopover(id);
    }
  };

  const isPopoverOpen = (id) => activePopover === id;
  const fileInputRef = useRef(null);

  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const [allUsers, setAllusers] = useState([]);
  const getalluser = () => {
    axios
      .get(`${config?.apiUrl}/api/get_all_users`)
      .then((response) => {
        const recruiterData = response.data.data;
        setAllusers(recruiterData);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  useEffect(() => {
    getalluser();
  }, []);

  return (
    <React.Fragment>
      <Modal
        show={searchCandidateModelShow}
        onHide={handleSearchCandidateClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="usertitle">
            Search Candidate
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table_box">
            <Row>
              <Col lg={8}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    filterCandidateData(searchcandidate);
                  }}
                  style={{ paddingBottom: "1rem" }}
                >
                  <div
                    style={{
                      width: "500px",
                      display: "flex",
                      gap: 5,
                      alignItems: "center",
                    }}
                  >
                    <InputGroup>
                      <InputLeftElement
                        children={
                          <img
                            src={Searchicon}
                            width="20px"
                            height="20px"
                            style={{ cursor: "pointer" }}
                          />
                        }
                        onClick={() => {
                          filterCandidateData(searchcandidate);
                        }}
                      />
                      <Input
                        type="text"
                        id="searchcandidate"
                        placeholder="Search Canidate Name, Designation, Location..."
                        value={searchcandidate}
                        onChange={(e) => {
                          setSearchcandidate(e.target.value);
                          setCandidateSearchActiveField({
                            name: "text",
                            value: e.target.value,
                          });
                        }}
                        style={{
                          height: "37px",
                          padding: "9px",
                          paddingLeft: "3rem",
                        }}
                      />
                      {searchcandidate.length !== 0 && (
                        <InputRightElement
                          children={
                            <CloseButton
                              size="md"
                              sx={{
                                background: "#fff",
                                color: "rgb(123, 154, 170)",
                              }}
                              _hover={{ background: "" }}
                              _focus={{ boxShadow: "none" }}
                            />
                          }
                          onClick={() => {
                            clearCandidateSearchFilter();
                          }}
                        />
                      )}
                    </InputGroup>
                    {(candidateSearchActiveField?.name || searchcandidate) && (
                      <Button
                        className="bg_color"
                        onClick={() => clearCandidateSearchFilter()}
                        style={{
                          color: "white",
                          border: "1px solid #d7d7d7",
                          fontWeight: "500",
                          letterSpacing: "1px",
                          fontSize: "14px",
                        }}
                      >
                        Reset
                      </Button>
                    )}
                  </div>
                </form>
              </Col>
              {jobpostview == "job" && (
                <Col lg={4}>
                  <Box className="button_box">
                    <Button
                      disabled={
                        candidateModuleData && candidateModuleData?.add == false
                      }
                      style={{
                        backgroundColor: "#023f61",
                        color: "white",
                        border: "1px solid #d7d7d7",
                        fontWeight: "500",
                        letterSpacing: "1px",
                        fontSize: "14px",
                      }}
                      className="bg_color"
                      onClick={() => {
                        handleCandidateShow();
                      }}
                    >
                      Add Candidate
                    </Button>
                    <Button
                      disabled={
                        candidateModuleData && candidateModuleData?.add == false
                      }
                      style={{
                        backgroundColor: "#023f61",
                        color: "white",
                        border: "1px solid #d7d7d7",
                        fontWeight: "500",
                        letterSpacing: "1px",
                        fontSize: "14px",
                      }}
                      className="bg_color"
                      onClick={handleFileInputClick}
                    >
                      Parse Resume
                    </Button>
                    <input
                      ref={fileInputRef}
                      disabled={
                        candidateModuleData && candidateModuleData?.add == false
                      }
                      type="file"
                      id="candidate-file-inp-jobview"
                      onChange={parseFile}
                      required="required"
                      accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                      style={{ cursor: "pointer" }}
                      hidden
                    />
                  </Box>
                </Col>
              )}
            </Row>
          </div>
          <div style={{ width: "98%", margin: "0 auto" }}>
            <Paper style={{ width: "100%", overflow: "hidden" }}>
              <TableContainer style={{ maxHeight: 680 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow sx={{ textAlignLast: "center" }}>
                      <TableCell>
                        Candidate Name{" "}
                        <PopoverSearchInput
                          setActivePopover={setActivePopover}
                          isPopoverOpen={isPopoverOpen}
                          openPopover={openPopover}
                          filedName={"firstName"}
                          filterJobpostData={filterCandidateData}
                          page={candidatesubmissionpage}
                          rowsPerPage={rowsPerCandidateSubmissionPage}
                          inputId={"firstName"}
                          inputPlaceholder={"Search Candidate Name..."}
                          inputValue={searchCandidateName || ""}
                          setInputValue={setSearchCandidateName}
                          setInputActiveValue={setCandidateSearchActiveField}
                        />{" "}
                      </TableCell>
                      <TableCell>
                        Candidate Designation{" "}
                        <PopoverSearchInput
                          setActivePopover={setActivePopover}
                          isPopoverOpen={isPopoverOpen}
                          openPopover={openPopover}
                          filedName={"designation"}
                          filterJobpostData={filterCandidateData}
                          page={candidatesubmissionpage}
                          rowsPerPage={rowsPerCandidateSubmissionPage}
                          inputId={"designation"}
                          inputPlaceholder={"Search Designation..."}
                          inputValue={searchCandidateDesignation || ""}
                          setInputValue={setSearchCandidateDesignation}
                          setInputActiveValue={setCandidateSearchActiveField}
                        />{" "}
                      </TableCell>
                      <TableCell>
                        Location{" "}
                        <PopoverSearchInput
                          setActivePopover={setActivePopover}
                          isPopoverOpen={isPopoverOpen}
                          openPopover={openPopover}
                          filedName={"city"}
                          filterJobpostData={filterCandidateData}
                          page={candidatesubmissionpage}
                          rowsPerPage={rowsPerCandidateSubmissionPage}
                          inputId={"city"}
                          inputPlaceholder={"Search Location..."}
                          inputValue={searchCandidateCity || ""}
                          setInputValue={setSearchCandidateCity}
                          setInputActiveValue={setCandidateSearchActiveField}
                        />{" "}
                      </TableCell>
                      <TableCell>
                        Candidate Owner{" "}
                        <PopoverDropDownSearchInput
                          setActivePopover={setActivePopover}
                          isPopoverOpen={isPopoverOpen}
                          openPopover={openPopover}
                          filedName={"candidate_ownerName"}
                          filterJobpostData={filterCandidateData}
                          page={candidatesubmissionpage}
                          rowsPerPage={rowsPerCandidateSubmissionPage}
                          inputId={"candidate_ownerName"}
                          inputPlaceholder={"Search Candidate Owner..."}
                          inputValue={searchCandidateOwnerName}
                          setInputValue={setSearchCandidateOwnerName}
                          setInputActiveValue={setCandidateSearchActiveField}
                          allUsers={allUsers}
                        />{" "}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <>
                        <TableRow>
                          <TableCell colSpan={3}>
                            <Loader />
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <>
                        {candidates.length == 0 ? (
                          <>
                            <TableRow>
                              <TableCell colSpan={11}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "column",
                                  }}
                                >
                                  <img
                                    src={NotFound}
                                    alt="NotFound"
                                    style={{ width: "16rem" }}
                                  />
                                  <p>No Records Found! Please try again....</p>
                                </div>
                              </TableCell>
                            </TableRow>
                          </>
                        ) : (
                          <>
                            {candidates &&
                              candidates.map((value, index) => (
                                <TableRow key={value._id}>
                                  <TableCell
                                    onClick={() => {
                                      setSubmission({
                                        ...submission,
                                        candidateId: value?._id,
                                        email: value?.email,
                                        file: value?.file,
                                        fileName: value?.fileName,
                                        filePath: value?.filePath,
                                        currentCandidateCTCType:
                                          value?.currentCandidateCTCType
                                            ? value?.currentCandidateCTCType
                                            : "Annual Salary",
                                        currentCandidateCTC:
                                          value?.currentCandidateCTC
                                            ? value?.currentCandidateCTC
                                            : "",
                                        expectedCandidateCTCType:
                                          value?.expectedCandidateCTCType
                                            ? value?.expectedCandidateCTCType
                                            : "Annual Salary",
                                        expectedCandidateCTC:
                                          value?.expectedCandidateCTC
                                            ? value?.expectedCandidateCTC
                                            : "",
                                        relevantExperiance: value?.experiance
                                          ? value?.experiance
                                          : "",
                                        noticePeriod: value?.noticePeriod
                                          ? value?.noticePeriod
                                          : "",
                                        candidate_ownerName:
                                          value?.candidate_ownerName
                                            ? value?.candidate_ownerName
                                            : "",
                                        isStatusChange: true,
                                      });
                                      Setcandidatename({
                                        ...candidatename,
                                        candiname:
                                          value?.firstName +
                                          " " +
                                          value?.lastName,
                                      });
                                      handleSubmitSubmissionShow();
                                      handleSearchCandidateClose();
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {value.firstName} {value.lastName}
                                  </TableCell>
                                  <TableCell>{value.designation}</TableCell>
                                  <TableCell>{value.city}</TableCell>
                                  <TableCell>
                                    {value.candidate_ownerName}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </>
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                labelRowsPerPage="Rows per page"
                rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                component="div"
                count={candidates?.totalCandidates}
                rowsPerPage={rowsPerCandidateSubmissionPage}
                page={candidatesubmissionpage}
                onPageChange={handleChangeCandidateSubmissionPage}
                onRowsPerPageChange={handleChangeRowsPerCandidateSubmissionPage}
              />
            </Paper>
          </div>
          <br />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default SearchCandidateModel;
