import { Box, Button, Text, useMediaQuery } from "@chakra-ui/react";
import { DateRangePicker } from "rsuite";
import { format } from "date-fns";
import React from "react";
import CommonCheckPicker from "../../CommonCheckPicker/CommonCheckPicker";

const FilterUserActivity = ({
  selectedUserName,
  setSelectedUserName,
  handleApplyFilters,
  handleResetFilters,
  userNameOptions,
  moduleOptions,
  selectedModules,
  setSelectedModules,
  setSearchMainDate,
  searchMainDate,
  page,
  rowsPerPage,
  setActiveField,
  activeField,
}) => {
  const [isMedia] = useMediaQuery("(max-width: 800px)");

  const userData = userNameOptions.map((user) => ({
    label: `${user}`,
    value: `${user}`,
  }));

  const moduleData = moduleOptions.map((module) => ({
    label: `${module}`,
    value: `${module}`,
  }));

  return (
    <>
      <Box
        display={["block", "block", "flex", "flex"]}
        justifyContent={"space-between"}
        width={"100%"}
        gap={10}
        paddingLeft={"10px"}
        mt={10}
        justifyItems={"center"}
      >
        <Box width={"100%"} gap={4}>
          <Text>User</Text>
          <CommonCheckPicker
            data={userData}
            value={selectedUserName}
            placeholder="Select Users"
            onChange={(selectedOption) => {
              setSelectedUserName(selectedOption);
              setActiveField({
                name: "username",
                value: selectedOption,
              });
            }}
            onClean={() => {
              handleApplyFilters(page, rowsPerPage, "username", "");
              setSelectedUserName([]);
              setActiveField({
                name: "username",
                value: "",
              });
            }}
          />
        </Box>
        <Box width={"100%"} gap={4}>
          <Text>Search Date</Text>
          <DateRangePicker
            style={{ width: "100%" }}
            placeholder="Select Date Range"
            format="dd.MM.yyyy"
            character=" – "
            menuClassName="reactDatePickerRange"
            value={searchMainDate}
            placement="autoVertical"
            onClean={() => {
              handleApplyFilters(page, rowsPerPage, "searchDate", "");
              setSearchMainDate([]);
              setActiveField({
                name: "searchDate",
                value: "",
              });
            }}
            onChange={(value) => {
              const formattedDates =
                value && value.map((date) => format(date, "yyyy-MM-dd"));

              setSearchMainDate(value);
              setActiveField({
                name: "searchDate",
                value: formattedDates,
              });
            }}
            showOneCalendar={!isMedia ? false : true}
          />
        </Box>
        <Box width={"100%"} gap={4}>
          <Text>Module</Text>
          <CommonCheckPicker
            data={moduleData}
            value={selectedModules}
            placeholder="Select Modules"
            onChange={(selectedOption) => {
              setSelectedModules(selectedOption);
              setActiveField({
                name: "module",
                value: selectedOption,
              });
            }}
            onClean={() => {
              handleApplyFilters(page, rowsPerPage, "module", "");
              setSelectedModules([]);
              setActiveField({
                name: "module",
                value: "",
              });
            }}
          />
        </Box>
        <Box width={"100%"} gap={4}>
          <Text>Action</Text>
          <Box display={"flex"} gap={3} borderRadius={"5px"}>
            <Button
              color={"#fff"}
              _hover={"none"}
              background={"#023f61"}
              onClick={() => {
                handleApplyFilters(
                  page,
                  rowsPerPage,
                  activeField.name,
                  activeField.value
                );
              }}
            >
              Apply
            </Button>
            <Button
              color={"#fff"}
              _hover={"none"}
              background={"#023f61"}
              onClick={handleResetFilters}
            >
              Reset
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FilterUserActivity;