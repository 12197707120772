import { Box, Flex, Heading } from "@chakra-ui/react";
import jsPDF from "jspdf";
import { useEffect, useState } from "react";
import FileViewer from "react-file-viewer";

const ParsedResumeDetails = ({
  resumeFilePath,
  toggleResumeDetails,
  showResumeDetails,
  file,
}) => {
  const [pdfUrl, setPdfUrl] = useState("");
  const [viewerError, setViewerError] = useState(null);
  const getBlobMimeType = async (blobUrl) => {
    try {
      const response = await fetch(blobUrl);
      const blob = await response.blob();

      return blob.type;
    } catch (error) {
      console.error("Error fetching Blob MIME type:", error);
      return null;
    }
  };

  const isPdfMimeType = (mimeType) => mimeType === "application/pdf";
  const isDocxMimeType = (mimeType) =>
    mimeType ===
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

  const convertToPdf = async () => {
    try {
      const blobMimeType = await getBlobMimeType(resumeFilePath);

      if (blobMimeType) {
        if (isPdfMimeType(blobMimeType)) {
          // If it's a PDF, set the PDF URL directly without conversion
          setPdfUrl(resumeFilePath);
        } else if (isDocxMimeType(blobMimeType)) {
          console.log("doc file");
        } else {
          // If it's neither a PDF nor DOCX, convert it to PDF as an image (example)
          const img = new Image();
          img.src = resumeFilePath;

          img.onload = () => {
            const pdf = new jsPDF();
            const imgWidth = 210; // A4 width in mm
            const imgHeight = (img.height * imgWidth) / img.width;

            pdf.addImage(resumeFilePath, "PNG", 0, 0, imgWidth, imgHeight);
            const pdfBytes = pdf.output("arraybuffer");

            const pdfDataUrl = URL.createObjectURL(
              new Blob([pdfBytes], { type: "application/pdf" })
            );
            setPdfUrl(pdfDataUrl);
          };
        }
      }
    } catch (error) {
      console.error("Error converting to PDF:", error);
    }
  };

  useEffect(() => {
    resumeFilePath && convertToPdf();
  }, [resumeFilePath, file]);

  return (
    <Box width={"100%"}>
      <Flex justifyContent={"space-between"}>
        <Heading as="h2" size="lg">
          Parsed Resume Details
        </Heading>
        {/* <Button onClick={toggleResumeDetails}>
          {showResumeDetails ? <CloseIcon /> : "Show Resume Details"}
        </Button> */}
      </Flex>
      {showResumeDetails && (
        <>
          {pdfUrl !== "" ? (
            <>
              <iframe
                title="Parsed Resume PDF"
                src={pdfUrl}
                style={{
                  width: "100%",
                  height: "80vh",
                  border: "none",
                  overflow: "auto",
                  userSelect: "text",
                }}
              ></iframe>
            </>
          ) : (
            <Box
              style={{
                overflow: "auto",
                userSelect: "text",
                height:"85vh"
              }}
            >
              {file &&
                file?.type ==
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                  <>
                    <FileViewer
                      fileType="docx"
                      filePath={resumeFilePath}
                      onError={(e) => {
                        setViewerError(e);
                      }}
                    />
                    {viewerError && <div>Error: {viewerError.message}</div>}
                  </>
                )}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ParsedResumeDetails;
