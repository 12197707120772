import React from "react";
import { CheckPicker, Checkbox } from "rsuite";

const CommonCheckPicker = ({
  data,
  value,
  placeholder,
  onChange,
  onClean,
  width,
}) => {
  const allValue = data.map((item) => item.value);

  const footerStyles = {
    padding: "7px 2px 0px",
    borderTop: "1px solid #e5e5e5",
  };

  const handleCheckAll = (_, checked) => {
    onChange(checked ? allValue : []);
  };

  return (
    <CheckPicker
      data={data}
      style={{ width: width ? width : 360 }}
      menuClassName="reactMultiSelectMenu"
      value={value}
      onClean={onClean}
      onChange={onChange}
      placeholder={placeholder}
      renderExtraFooter={() => (
        <div style={footerStyles}>
          <Checkbox
            indeterminate={value.length > 0 && value.length < allValue.length}
            checked={value.length === allValue.length}
            onChange={handleCheckAll}
          >
            Check all
          </Checkbox>
        </div>
      )}
    />
  );
};

export default CommonCheckPicker;
