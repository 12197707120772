import { Box, Button, Checkbox } from "@chakra-ui/react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { formatHeader } from "Utils";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Modal from "react-bootstrap/Modal";

const CustomizeColumns = ({
  ViewSubmheaderOrders,
  handleSubmHeaderOrdersClose,
  onDragEnd,
  displaySubmheaderOrders,
  defaultDisplaySubmHeaderOrders,
  handleCheckboxChange,
  handleApplyClick,
  candidates,
  filename,
  Changeoption,
}) => {
  return (
    <>
      <div className="model-box-view">
        <Modal
          show={ViewSubmheaderOrders}
          onHide={handleSubmHeaderOrdersClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title className="usertitle">Customize Columns</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="characters">
                {(provided) => (
                  <Box
                    className="characters"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    {displaySubmheaderOrders.map((key, i) => (
                      <Draggable key={i} draggableId={i.toString()} index={i}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Box
                              style={{
                                backgroundColor: "#f7f7f7",
                                padding: "6px",
                                textTransform: "capitalize",
                                borderRadius: "7px",
                              }}
                            >
                              <DragIndicatorIcon
                                style={{
                                  color: "grey",
                                  marginRight: "0.8rem",
                                }}
                              />
                              <Checkbox
                                isChecked={defaultDisplaySubmHeaderOrders[key]}
                                onChange={(e) =>
                                  handleCheckboxChange(key, e.target.checked)
                                }
                              >
                                {formatHeader(key)}
                              </Checkbox>
                            </Box>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Modal.Body>

          <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
            <Button className="cancel" onClick={handleSubmHeaderOrdersClose}>
              Cancel
            </Button>
            <Button
              className="bg_color"
              color={"#fff"}
              type="submit"
              onClick={async () => {
                (await Changeoption) && Changeoption();
                handleApplyClick(candidates, filename);
              }}
            >
              Apply
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default CustomizeColumns;
