import { createSlice } from "@reduxjs/toolkit";
import { usersApi } from "../api/users";

export const usersSlice = createSlice({
  name: "usersSlice",
  initialState: {
    userNames: [],
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      usersApi.endpoints.getUsersNames.matchFulfilled,
      (state, { payload }) => {
        state.userNames = payload.users || [];
      }
    );
  },
});
