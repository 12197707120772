import {
  CloseButton,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import Searchicon from "assets/svg/search-icon.svg";
import React from "react";
import { CiSearch } from "react-icons/ci";
const InputTextSearchBox = ({
  filterData,
  inputState,
  fieldName,
  setInputState,
  setActiveField,
  activeFieldName,
  isFilterActiveState,
  inputPlaceholder,
}) => {
  return (
    <React.Fragment>
      <div style={{ width: "200px" }}>
        <InputGroup>
          <InputLeftElement
          // children={
          //   <img
          //     src={Searchicon}
          //     width="18px"
          //     height="18px"
          //     style={{ cursor: "pointer" }}
          //   />
          // }
          // onClick={() => {
          //   filterData(inputState, fieldName);
          // }}
          >
            <IconButton
              disabled={!inputState}
              _hover={{ background: "transparent" }}
              background={"transparent"}
              _focus={{ outline: "none" }}
              onClick={() => {
                filterData(inputState, fieldName);
              }}
            >
              <CiSearch style={{ fontSize: "1.2rem" }} />
            </IconButton>
          </InputLeftElement>
          <Input
            type="text"
            id={activeFieldName == "skill" ? "skill-1" : activeFieldName}
            value={inputState}
            placeholder={inputPlaceholder}
            onChange={(e) => {
              setInputState(e.target.value);
              setActiveField({
                name: activeFieldName,
                value: e.target.value,
              });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                filterData(inputState, fieldName);
              }
            }}
            style={{
              height: "37px",
              padding: "9px",
              paddingLeft: "2.6rem",
              paddingRight: !isFilterActiveState ? "1rem" : "2.3rem",
              color: "#7B9AAA",
              fontFamily: "Roboto",
              fontWeight: "500",
              lineHeight: "19px",
              fontSize: "15px",
            }}
          />
          {isFilterActiveState && (
            <InputRightElement style={{ height: "2.3rem" }}>
              <CloseButton
                size="sm"
                onClick={() => {
                  filterData("", fieldName);
                  setInputState("");
                  setActiveField({
                    name: activeFieldName,
                    value: "",
                  });
                }}
                _focus={{ boxShadow: "none" }}
              />
            </InputRightElement>
          )}
        </InputGroup>
      </div>
    </React.Fragment>
  );
};

export default InputTextSearchBox;
