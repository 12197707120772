import { HamburgerIcon } from "@chakra-ui/icons";
import { Box, Flex, Tooltip } from "@chakra-ui/react";
import axios from "axios";
import SelectUserRoles from "components/Commoncomponent/Securitycomponent/SelectUserRoles";
import MainPanel from "components/Layout/MainPanel";
import PanelContainer from "components/Layout/PanelContainer";
import PanelContent from "components/Layout/PanelContent";
import AdminNavbar from "components/Navbars/AdminNavbar";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Route, Switch, useHistory } from "react-router-dom";
import Accountview from "views/Dashboard/Accountview";
import Candidateview from "views/Dashboard/Candidateview";
import Contactview from "views/Dashboard/Contactview";
import CreateForm from "views/Dashboard/CreateForm";
import Jobpostview from "views/Dashboard/Jobpostview";
import Submissionview from "views/Dashboard/Submissionview";
import Sidebar from "../components/Sidebar/Sidebar";
import routes from "../routes";
import "../views/Dashboard/org.css";
import Organizationview from "views/Dashboard/Organizationview";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../redux/reducers/sidebar";

const DetailsView = ({ children }) => {
  const { collapsed } = useSelector((state) => state.sidebar);
  const dispatch = useDispatch();
  const [cookie] = useCookies(["role"]);
  const userRole = localStorage.getItem("role");

  const history = useHistory();
  

  const handleGoBack = () => {
    history?.goBack();
  };

  const [moduleData, setModuleData] = useState({});

  const fetchModuleData = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/api/getpermissions/${cookie.role}`
      );
      const modules = response.data.modules;

      const moduleData = {};
      modules.forEach((module) => {
        moduleData[module.module] = module?.permissions.component;
      });

      setModuleData(moduleData);
    } catch (error) {
      console.error("Error while fetching module data:", error);
    }
  };

  useEffect(() => {
    if (cookie?.role && cookie?.role !== "Admin") {
      fetchModuleData();
    }
  }, [cookie?.role]);

  const detailRoutes = [
    {
      path: "/accountview/:id",
      name: "Accountview",
      component: Accountview,
      layout: "/details",
    },
    {
      path: "/candidateview/:id",
      name: "Candidateview",
      component: Candidateview,
      layout: "/details",
    },
    {
      path: "/jobpostview/:id",
      name: "Jobpostview",
      component: Jobpostview,
      layout: "/details",
    },
    {
      path: "/contactview/:id",
      name: "Contactview",
      component: Contactview,
      layout: "/details",
    },
    {
      path: "/submissionview/:id",
      name: "Submissionview",
      component: Submissionview,
      layout: "/details",
    },
    {
      path: "/SelectUserRoles",
      name: "SelectUserRoles",
      component: SelectUserRoles,
      layout: "/details",
    },
    {
      path: "/CreateForm",
      name: "CreateForm",
      component: CreateForm,
      layout: "/details",
    },
    {
      path: "/editform/:id",
      name: "CreateForm",
      component: CreateForm,
      layout: "/details",
    },
    {
      path: "/organizationview/:id",
      name: "Organizationview",
      component: Organizationview,
      layout: "/details",
    },
  ];

  const filterRoutes = () => {
    let filteredRoutes = [...routes]; // Copying the original routes array

    if (moduleData?.submission == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/submission")
      );
    }
    if (moduleData?.Jobs == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/jobpost")
      );
    }
    if (moduleData?.candidate == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/candidate")
      );
    }
    if (moduleData?.candidate == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/pendingcandidate")
      );
    }
    if (moduleData?.client == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/accounts")
      );
    }
    if (moduleData?.contact == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/contact")
      );
    }
    if (moduleData?.interviewattained == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/interviewattained")
      );
    }
    if (moduleData?.interviewcomplete == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/interviewcomplete")
      );
    }
    if (moduleData?.interviewschedule == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/interviewschedule")
      );
    }
    if (moduleData?.joined == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/joined")
      );
    }
    if (moduleData?.qacheckcandidate == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/qacheckcandidate")
      );
    }
    if (moduleData?.selected == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/selected")
      );
    }
    if (moduleData?.setting == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/setting")
      );
    }
    if (moduleData?.simpleform == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/simpleform")
      );
    }
    if (moduleData?.useractivity == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/useractivity")
      );
    }
    if (moduleData?.rejected == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/rejected")
      );
    }
    if (moduleData?.backoutcandidate == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/backoutcandidate")
      );
    }

    return filteredRoutes;
  };

  const getRoutes = (filterRoute) => {
    return filterRoute.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.category === "account") {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/details") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <React.Fragment>
      <Box
        minH="15rem"
        w="100%"
        position="absolute"
        className="bg_color"
        top="0"
      />

      {/* <Box
        left={isSidebarOpen ? "0" : "-400px"}
        transition="left 0.3s ease-in-out"
        position="fixed"
        overflowY="auto"
      > */}
        <Sidebar routes={cookie.role !== "Admin" ? filterRoutes() : routes} />
      {/* </Box> */}
      <MainPanel
        w={{
          base: "100%",
          xl: !collapsed ? "calc(100% - 275px)" : "95%",
        }}
      >
        <Flex
          padding={"30px 30px 0px 30px"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box
            display={"flex"}
            cursor={"pointer"}
            alignItems={"center"}
            gap={5}
          >
            <HamburgerIcon
              color={"#fff"}
              w="30px"
              h="30px"
              onClick={() => dispatch(toggleSidebar())}
              display={["none", "none", "none", "none", "flex"]}
            />
            <Tooltip
              hasArrow
              label="GO BACK"
              bg="gray.300"
              color="black"
              style={{ marginTop: "20px !imoratant" }}
            >
              <div
                className={collapsed ? "back_arrow" : "back_arrow_nav"}
                style={{ marginTop: "3px" }}
                onClick={handleGoBack}
              >
                <i className="fa fa-arrow-left"></i>
              </div>
            </Tooltip>
          </Box>
          <AdminNavbar />
        </Flex>
        <Box>
          <PanelContent>
            <PanelContainer>
              <Switch>
                {getRoutes(detailRoutes)}
                {/* <Redirect to="/admin/dashboard" /> */}
              </Switch>
            </PanelContainer>
          </PanelContent>
        </Box>
      </MainPanel>
    </React.Fragment>
  );
};

export default DetailsView;
