import React, { useEffect, useState } from "react";

import axios from "axios";
import config from "../../../config";
import { toast } from "react-toastify";
import {
  Modal,
  Container,
  Row,
  Col,
  ModalBody,
  Button,
  Card,
  ListGroup,
} from "react-bootstrap";
import { Text, useColorModeValue } from "@chakra-ui/react";

const CandidatePreview = ({
  isOpenPreview,
  setIsOpenPreview,
  handleCandidatePreviewClose,
  perviewCandidateId,
  setPerviewCandidateId,
}) => {
  const [candidatePreviewData, setCandidatePreviewData] = useState({});

  const getCandidate = () => {
    axios
      .get(`${config?.apiUrl}/candidateview/${perviewCandidateId}`)
      .then((response) => {
        const candidatedata = response.data.data;
        setCandidatePreviewData(candidatedata);
      })
      .catch((err) => {
        toast.error(
          "Something happened wrong, please try again after sometime.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      });
  };

  const truncateSkill = (skill) => {
    if (!skill) return "N/A";
    if (skill.length > 80) {
      return `${skill.slice(0, 80)}...`;
    }
    return skill;
  };

  useEffect(() => {
    perviewCandidateId && getCandidate();
  }, [perviewCandidateId]);

  return (
    <>
      <Modal
        show={isOpenPreview}
        onHide={() => {
          handleCandidatePreviewClose();
          setPerviewCandidateId("");
        }}
        size="xl"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Candidate Preview</Modal.Title>
        </Modal.Header>
        <ModalBody style={{ maxHeight: "80vh", overflowY: "auto" }}>
          {!candidatePreviewData ? (
            <>
              <Text>There is no any data</Text>
            </>
          ) : (
            <>
              <Container style={{ minWidth: "100%" }}>
                <Row>
                  <Col lg={4} md={6} sm={4} style={{ marginBottom: "1rem" }}>
                    <Text
                      color={useColorModeValue("gray.600", "gray.400")}
                      style={{ fontWeight: "bold" }}
                    >
                      FirstName
                    </Text>
                    <span>
                      {!candidatePreviewData?.firstName
                        ? "N/A"
                        : candidatePreviewData?.firstName}
                    </span>
                  </Col>
                  <Col lg={4} md={6} sm={4} style={{ marginBottom: "1rem" }}>
                    <Text
                      color={useColorModeValue("gray.600", "gray.400")}
                      style={{ fontWeight: "bold" }}
                    >
                      LastName
                    </Text>
                    <span>
                      {!candidatePreviewData?.lastName
                        ? "N/A"
                        : candidatePreviewData?.lastName}
                    </span>
                  </Col>
                  <Col lg={4} md={6} sm={4} style={{ marginBottom: "1rem" }}>
                    <Text
                      color={useColorModeValue("gray.600", "gray.400")}
                      style={{ fontWeight: "bold" }}
                    >
                      Email
                    </Text>
                    <span>
                      {!candidatePreviewData?.email
                        ? "N/A"
                        : candidatePreviewData?.email}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} md={6} sm={4} style={{ marginBottom: "1rem" }}>
                    <Text
                      color={useColorModeValue("gray.600", "gray.400")}
                      style={{ fontWeight: "bold" }}
                    >
                      Mobile
                    </Text>
                    <span>
                      {!candidatePreviewData?.phone
                        ? "N/A"
                        : candidatePreviewData?.phone}
                    </span>
                  </Col>
                  <Col lg={4} md={6} sm={4} style={{ marginBottom: "1rem" }}>
                    <Text
                      color={useColorModeValue("gray.600", "gray.400")}
                      style={{ fontWeight: "bold" }}
                    >
                      Years of Experience
                    </Text>
                    <span>
                      {!candidatePreviewData?.experiance
                        ? "N/A"
                        : candidatePreviewData?.experiance}
                    </span>
                  </Col>
                  <Col lg={4} md={6} sm={4} style={{ marginBottom: "1rem" }}>
                    <Text
                      color={useColorModeValue("gray.600", "gray.400")}
                      style={{ fontWeight: "bold" }}
                    >
                      Candidate Designation
                    </Text>
                    <span>
                      {!candidatePreviewData?.designation
                        ? "N/A"
                        : candidatePreviewData?.designation}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} md={6} sm={4} style={{ marginBottom: "1rem" }}>
                    <Text
                      color={useColorModeValue("gray.600", "gray.400")}
                      style={{ fontWeight: "bold" }}
                    >
                      Candidate Status
                    </Text>
                    <span>
                      {!candidatePreviewData?.candidateStatus
                        ? "N/A"
                        : candidatePreviewData?.candidateStatus}
                    </span>
                  </Col>
                  <Col lg={4} md={6} sm={4} style={{ marginBottom: "1rem" }}>
                    <Text
                      color={useColorModeValue("gray.600", "gray.400")}
                      style={{ fontWeight: "bold" }}
                    >
                      Date of Birth
                    </Text>
                    <span>
                      {!candidatePreviewData?.dateofBirth
                        ? "N/A"
                        : candidatePreviewData?.dateofBirth}
                    </span>
                  </Col>
                  <Col lg={4} md={6} sm={4} style={{ marginBottom: "1rem" }}>
                    <Text
                      color={useColorModeValue("gray.600", "gray.400")}
                      style={{ fontWeight: "bold" }}
                    >
                      Skills
                    </Text>
                    <span
                      title={candidatePreviewData?.skill}
                      style={{ cursor: "pointer" }}
                    >
                      {truncateSkill(candidatePreviewData?.skill)}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} md={6} sm={4} style={{ marginBottom: "1rem" }}>
                    <Text
                      color={useColorModeValue("gray.600", "gray.400")}
                      style={{ fontWeight: "bold" }}
                    >
                      Country
                    </Text>
                    <span>
                      {!candidatePreviewData?.country
                        ? "N/A"
                        : candidatePreviewData?.country}
                    </span>
                  </Col>
                  <Col lg={4} md={6} sm={4} style={{ marginBottom: "1rem" }}>
                    <Text
                      color={useColorModeValue("gray.600", "gray.400")}
                      style={{ fontWeight: "bold" }}
                    >
                      State
                    </Text>
                    <span>
                      {!candidatePreviewData?.state
                        ? "N/A"
                        : candidatePreviewData?.state}
                    </span>
                  </Col>
                  <Col lg={4} md={6} sm={4} style={{ marginBottom: "1rem" }}>
                    <Text
                      color={useColorModeValue("gray.600", "gray.400")}
                      style={{ fontWeight: "bold" }}
                    >
                      City
                    </Text>
                    <span>
                      {!candidatePreviewData?.city
                        ? "N/A"
                        : candidatePreviewData?.city}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} md={6} sm={4} style={{ marginBottom: "1rem" }}>
                    <Text
                      color={useColorModeValue("gray.600", "gray.400")}
                      style={{ fontWeight: "bold" }}
                    >
                      PAN Card Number
                    </Text>
                    <span>
                      {!candidatePreviewData?.PancardNumber
                        ? "N/A"
                        : candidatePreviewData?.PancardNumber}
                    </span>
                  </Col>
                  <Col lg={4} md={6} sm={4} style={{ marginBottom: "1rem" }}>
                    <Text
                      color={useColorModeValue("gray.600", "gray.400")}
                      style={{ fontWeight: "bold" }}
                    >
                      Aadhar Card Number
                    </Text>
                    <span>
                      {!candidatePreviewData?.AadharcardNumber
                        ? "N/A"
                        : candidatePreviewData?.AadharcardNumber}
                    </span>
                  </Col>
                  <Col lg={4} md={6} sm={4} style={{ marginBottom: "1rem" }}>
                    <Text
                      color={useColorModeValue("gray.600", "gray.400")}
                      style={{ fontWeight: "bold" }}
                    >
                      Gender
                    </Text>
                    <span>
                      {!candidatePreviewData?.gender
                        ? "N/A"
                        : candidatePreviewData?.gender}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} md={6} sm={4} style={{ marginBottom: "1rem" }}>
                    <Text
                      color={useColorModeValue("gray.600", "gray.400")}
                      style={{ fontWeight: "bold" }}
                    >
                      Address
                    </Text>
                    <span>
                      {!candidatePreviewData?.address
                        ? "N/A"
                        : candidatePreviewData?.address}
                    </span>
                  </Col>
                  <Col lg={4} md={6} sm={4} style={{ marginBottom: "1rem" }}>
                    <Text
                      color={useColorModeValue("gray.600", "gray.400")}
                      style={{ fontWeight: "bold" }}
                    >
                      Pincode
                    </Text>
                    <span>
                      {!candidatePreviewData?.pincode
                        ? "N/A"
                        : candidatePreviewData?.pincode}
                    </span>
                  </Col>
                  <Col lg={4} md={6} sm={4} style={{ marginBottom: "1rem" }}>
                    <Text
                      color={useColorModeValue("gray.600", "gray.400")}
                      style={{ fontWeight: "bold" }}
                    >
                      Source
                    </Text>
                    <span>
                      {!candidatePreviewData?.source
                        ? "N/A"
                        : candidatePreviewData?.source}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} md={6} sm={4} style={{ marginBottom: "1rem" }}>
                    <Text
                      color={useColorModeValue("gray.600", "gray.400")}
                      style={{ fontWeight: "bold" }}
                    >
                      Candidate Application Status
                    </Text>
                    <span>
                      {!candidatePreviewData?.status
                        ? "N/A"
                        : candidatePreviewData?.status}
                    </span>
                  </Col>
                </Row>
                {candidatePreviewData &&
                  candidatePreviewData?.educationDetails?.length !== 0 && (
                    <h1
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "22px",
                        marginBottom: "10px",
                      }}
                    >
                      {" "}
                      Education Details
                    </h1>
                  )}

                {candidatePreviewData &&
                  candidatePreviewData?.educationDetails?.map((education) => (
                    <>
                      <Card
                        style={{ width: "100%", marginBottom: "1rem" }}
                        key={education.institutename}
                      >
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <Container style={{ minWidth: "100%" }}>
                              <Row>
                                <Col
                                  lg={4}
                                  md={6}
                                  sm={4}
                                  style={{ marginBottom: "1rem" }}
                                >
                                  <Text
                                    color={useColorModeValue(
                                      "gray.600",
                                      "gray.400"
                                    )}
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Institute Name
                                  </Text>
                                  <span>
                                    {!education?.institutename
                                      ? "N/A"
                                      : education?.institutename}
                                  </span>
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  sm={4}
                                  style={{ marginBottom: "1rem" }}
                                >
                                  <Text
                                    color={useColorModeValue(
                                      "gray.600",
                                      "gray.400"
                                    )}
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Degree
                                  </Text>
                                  <span>
                                    {!education?.degree
                                      ? "N/A"
                                      : education?.degree}
                                  </span>
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  sm={4}
                                  style={{ marginBottom: "1rem" }}
                                >
                                  <Text
                                    color={useColorModeValue(
                                      "gray.600",
                                      "gray.400"
                                    )}
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Start Date
                                  </Text>
                                  <span>
                                    {!education?.startDate
                                      ? "N/A"
                                      : education?.startDate}
                                  </span>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  lg={4}
                                  md={6}
                                  sm={4}
                                  style={{ marginBottom: "1rem" }}
                                >
                                  <Text
                                    color={useColorModeValue(
                                      "gray.600",
                                      "gray.400"
                                    )}
                                    style={{ fontWeight: "bold" }}
                                  >
                                    End Date
                                  </Text>
                                  <span>
                                    {!education?.endDate
                                      ? "N/A"
                                      : education?.endDate}
                                  </span>
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  sm={4}
                                  style={{ marginBottom: "1rem" }}
                                >
                                  <Text
                                    color={useColorModeValue(
                                      "gray.600",
                                      "gray.400"
                                    )}
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Percentage
                                  </Text>
                                  <span>
                                    {!education?.percentage
                                      ? "N/A"
                                      : education?.percentage}
                                  </span>
                                </Col>
                                <Col
                                  lg={4}
                                  md={6}
                                  sm={4}
                                  style={{ marginBottom: "1rem" }}
                                >
                                  <Text
                                    color={useColorModeValue(
                                      "gray.600",
                                      "gray.400"
                                    )}
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Category
                                  </Text>
                                  <span>
                                    {!education?.category
                                      ? "N/A"
                                      : education?.category}
                                  </span>
                                </Col>
                              </Row>
                            </Container>
                          </ListGroup.Item>
                        </ListGroup>
                      </Card>
                    </>
                  ))}
                {candidatePreviewData &&
                  candidatePreviewData?.workExperience?.length !== 0 && (
                    <h1
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "22px",
                        marginBottom: "10px",
                      }}
                    >
                      {" "}
                      Work Experience Details
                    </h1>
                  )}

                {candidatePreviewData &&
                  candidatePreviewData?.workExperience?.map((work) => (
                    <Card
                      style={{ width: "100%", marginBottom: "1rem" }}
                      key={work.companyname}
                    >
                      <ListGroup variant="flush">
                        <ListGroup.Item style={{ textAlign: "left" }}>
                          <Container style={{ minWidth: "100%" }}>
                            <Row>
                              <Col
                                lg={4}
                                md={6}
                                sm={4}
                                style={{ marginBottom: "1rem" }}
                              >
                                <Text
                                  color={useColorModeValue(
                                    "gray.600",
                                    "gray.400"
                                  )}
                                  style={{ fontWeight: "bold" }}
                                >
                                  Company Name
                                </Text>
                                <span>
                                  {!work?.companyname
                                    ? "N/A"
                                    : work?.companyname}
                                </span>
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                sm={4}
                                style={{ marginBottom: "1rem" }}
                              >
                                <Text
                                  color={useColorModeValue(
                                    "gray.600",
                                    "gray.400"
                                  )}
                                  style={{ fontWeight: "bold" }}
                                >
                                  Designation
                                </Text>
                                <span>
                                  {!work?.designation
                                    ? "N/A"
                                    : work?.designation}
                                </span>
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                sm={4}
                                style={{ marginBottom: "1rem" }}
                              >
                                <Text
                                  color={useColorModeValue(
                                    "gray.600",
                                    "gray.400"
                                  )}
                                  style={{ fontWeight: "bold" }}
                                >
                                  Start Date
                                </Text>
                                <span>
                                  {!work?.startDate ? "N/A" : work?.startDate}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                lg={4}
                                md={6}
                                sm={4}
                                style={{ marginBottom: "1rem" }}
                              >
                                <Text
                                  color={useColorModeValue(
                                    "gray.600",
                                    "gray.400"
                                  )}
                                  style={{ fontWeight: "bold" }}
                                >
                                  End Date
                                </Text>
                                <span>
                                  {!work?.endDate ? "N/A" : work?.endDate}
                                </span>
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                sm={4}
                                style={{ marginBottom: "1rem" }}
                              >
                                <Text
                                  color={useColorModeValue(
                                    "gray.600",
                                    "gray.400"
                                  )}
                                  style={{ fontWeight: "bold" }}
                                >
                                  Country
                                </Text>
                                <span>
                                  {!work?.country ? "N/A" : work?.country}
                                </span>
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                sm={4}
                                style={{ marginBottom: "1rem" }}
                              >
                                <Text
                                  color={useColorModeValue(
                                    "gray.600",
                                    "gray.400"
                                  )}
                                  style={{ fontWeight: "bold" }}
                                >
                                  State
                                </Text>
                                <span>
                                  {!work?.state ? "N/A" : work?.state}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                lg={4}
                                md={6}
                                sm={4}
                                style={{ marginBottom: "1rem" }}
                              >
                                <Text
                                  color={useColorModeValue(
                                    "gray.600",
                                    "gray.400"
                                  )}
                                  style={{ fontWeight: "bold" }}
                                >
                                  City
                                </Text>
                                <span>{!work?.city ? "N/A" : work?.city}</span>
                              </Col>
                            </Row>
                          </Container>
                        </ListGroup.Item>
                      </ListGroup>
                    </Card>
                  ))}
                {candidatePreviewData &&
                  candidatePreviewData?.referenceDetail && (
                    <h1
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "22px",
                        marginBottom: "10px",
                      }}
                    >
                      {" "}
                      Reference Details
                    </h1>
                  )}
                {candidatePreviewData &&
                  candidatePreviewData?.referenceDetail?.map((reference) => (
                    <Card
                      style={{ width: "100%", marginBottom: "1rem" }}
                      key={reference.referenceType}
                    >
                      <ListGroup variant="flush">
                        <ListGroup.Item style={{ textAlign: "left" }}>
                          <Container style={{ minWidth: "100%" }}>
                            <Row>
                              <Col
                                lg={4}
                                md={6}
                                sm={4}
                                style={{ marginBottom: "1rem" }}
                              >
                                <Text
                                  color={useColorModeValue(
                                    "gray.600",
                                    "gray.400"
                                  )}
                                  style={{ fontWeight: "bold" }}
                                >
                                  Reference Type
                                </Text>
                                <span>
                                  {!reference?.referenceType
                                    ? "N/A"
                                    : reference?.referenceType}
                                </span>
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                sm={4}
                                style={{ marginBottom: "1rem" }}
                              >
                                <Text
                                  color={useColorModeValue(
                                    "gray.600",
                                    "gray.400"
                                  )}
                                  style={{ fontWeight: "bold" }}
                                >
                                  Reference Name
                                </Text>
                                <span>
                                  {!reference?.referenceName
                                    ? "N/A"
                                    : reference?.referenceName}
                                </span>
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                sm={4}
                                style={{ marginBottom: "1rem" }}
                              >
                                <Text
                                  color={useColorModeValue(
                                    "gray.600",
                                    "gray.400"
                                  )}
                                  style={{ fontWeight: "bold" }}
                                >
                                  Company Name
                                </Text>
                                <span>
                                  {!reference?.companyName
                                    ? "N/A"
                                    : reference?.companyName}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                lg={4}
                                md={6}
                                sm={4}
                                style={{ marginBottom: "1rem" }}
                              >
                                <Text
                                  color={useColorModeValue(
                                    "gray.600",
                                    "gray.400"
                                  )}
                                  style={{ fontWeight: "bold" }}
                                >
                                  Designation
                                </Text>
                                <span>
                                  {!reference?.designation
                                    ? "N/A"
                                    : reference?.designation}
                                </span>
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                sm={4}
                                style={{ marginBottom: "1rem" }}
                              >
                                <Text
                                  color={useColorModeValue(
                                    "gray.600",
                                    "gray.400"
                                  )}
                                  style={{ fontWeight: "bold" }}
                                >
                                  Email
                                </Text>
                                <span>
                                  {!reference?.email ? "N/A" : reference?.email}
                                </span>
                              </Col>
                              <Col
                                lg={4}
                                md={6}
                                sm={4}
                                style={{ marginBottom: "1rem" }}
                              >
                                <Text
                                  color={useColorModeValue(
                                    "gray.600",
                                    "gray.400"
                                  )}
                                  style={{ fontWeight: "bold" }}
                                >
                                  Phone
                                </Text>
                                <span>
                                  {!reference?.phone ? "N/A" : reference?.phone}
                                </span>
                              </Col>
                            </Row>
                          </Container>
                        </ListGroup.Item>
                      </ListGroup>
                    </Card>
                  ))}
              </Container>
            </>
          )}
        </ModalBody>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          <Button
            style={{ width: "12%" }}
            variant="secondary"
            className="bg_color"
            color={"#fff"}
            onClick={() => {
              handleCandidatePreviewClose();
              setPerviewCandidateId("");
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CandidatePreview;
