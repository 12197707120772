import React from "react";
import ActivityReport from "./ActivityReport";

const UserActivity = () => {
  return (
    <>
      <ActivityReport />
    </>
  );
};

export default UserActivity;
