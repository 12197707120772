import { HamburgerIcon } from "@chakra-ui/icons";
import { Box, Spinner, Stack, useColorMode } from "@chakra-ui/react";
import axios from "axios";
import Footer from "components/Footer/Footer.js";
import {
  ArgonLogoDark,
  ArgonLogoLight,
  ChakraLogoDark,
  ChakraLogoLight,
} from "components/Icons/Icons";
import Sidebar from "components/Sidebar/Sidebar.js";
import { Suspense, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Redirect, Route, Switch } from "react-router-dom";
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import config from "../config";
import routes from "../routes.js";
import { useSelector, useDispatch } from "react-redux";
import { toggleSidebar } from "../redux/reducers/sidebar";

export default function Dashboard(props) {
  const { collapsed } = useSelector((state) => state.sidebar);
  const dispatch = useDispatch();
  const [cookie] = useCookies(["role"]);
  const [moduleData, setModuleData] = useState({});
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state

  const fetchModuleData = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/api/getpermissions/${cookie.role}`
      );
      const modules = response.data.modules;

      const moduleData = {};
      modules.forEach((module) => {
        moduleData[module.module] = module?.permissions.component;
      });
      setModuleData(moduleData);
    } catch (error) {
      console.error("Error while fetching module data:", error);
      setError(error); // Set error state
    } finally {
      setLoading(false); // Set loading to false after fetch completes
    }
  };

  useEffect(() => {
    if (cookie?.role && cookie?.role !== "Admin") {
      fetchModuleData();
    } else {
      setLoading(false); // If role is Admin, no need to fetch data
    }
  }, [cookie?.role]);

  const filterRoutes = () => {
    let filteredRoutes = [...routes]; // Copying the original routes array

    // Apply filtering logic based on moduleData
    if (!moduleData?.submission) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/submission")
      );
    }
    if (!moduleData?.Jobs) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/jobpost")
      );
    }
    if (!moduleData?.candidate) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/candidate")
      );
    }
    if (!moduleData?.candidate) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/pendingcandidate")
      );
    }
    if (!moduleData?.client) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/accounts")
      );
    }
    if (!moduleData?.contact) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/contact")
      );
    }
    if (!moduleData?.interviewattained) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/interviewattained")
      );
    }
    if (!moduleData?.interviewcomplete) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/interviewcomplete")
      );
    }
    if (!moduleData?.interviewschedule) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/interviewschedule")
      );
    }
    if (!moduleData?.joined) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/joined")
      );
    }
    if (!moduleData?.qacheckcandidate) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/qacheckcandidate")
      );
    }
    if (!moduleData?.selected) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/selected")
      );
    }
    if (!moduleData?.setting) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/setting")
      );
    }
    if (!moduleData?.simpleform) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/simpleform")
      );
    }
    if (!moduleData?.useractivity) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/useractivity")
      );
    }
    if (!moduleData?.rejected) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/rejected")
      );
    }
    if (!moduleData?.backoutcandidate) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/backoutcandidate")
      );
    }
    console.log("filteredRoutes============", filteredRoutes);

    return filteredRoutes;
  };

  const filteredRoutes = cookie.role !== "Admin" ? filterRoutes() : routes; // Get filtered routes based on role
  const { colorMode } = useColorMode();

  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

  // Component Rendering


  if (error) {
    return <div>Error fetching data: {error.message}</div>; // Show error message
  }

  return (
    <Box pos={"relative"}>
      {loading ? (
        <Box width={'full'}  h={'100vh'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Spinner />
        </Box>
      ) : (
        <>
          <Box
            minH="19rem"
            w="100%"
            position="absolute"
            className="bg_color"
            top="0"
          />
          <Sidebar
            routes={cookie.role !== "Admin" ? filterRoutes() : routes}
            logo={
              <Stack
                direction="row"
                spacing="12px"
                align="center"
                justify="center"
              >
                {colorMode === "dark" ? (
                  <ArgonLogoLight w="74px" h="27px" />
                ) : (
                  <ArgonLogoDark w="74px" h="27px" />
                )}
                <Box
                  w="1px"
                  h="20px"
                  bg={colorMode === "dark" ? "white" : "gray.700"}
                />
                {colorMode === "dark" ? (
                  <ChakraLogoLight w="82px" h="21px" />
                ) : (
                  <ChakraLogoDark w="82px" h="21px" />
                )}
              </Stack>
            }
            display="none"
            {...props}
          />
          <MainPanel
            w={{
              base: "100%",
              xl: !collapsed ? "calc(100% - 275px)" : "95%",
            }}
          >
            <Box
              display={"flex"}
              cursor={"pointer"}
              alignItems={"center"}
              justifyContent={{ base: "flex-end", xl: "space-between" }}
              padding={"30px 30px 0px 30px"}
            >
              <HamburgerIcon
                color={"#fff"}
                w="30px"
                h="30px"
                onClick={() => dispatch(toggleSidebar())}
                display={["none", "none", "none", "none", "flex"]}
              />
              <AdminNavbar />
            </Box>
            {getRoute() ? (
              <PanelContent>
                <PanelContainer>
                  <Suspense
                    fallback={
                      <div className="loading">
                        <span className="main_loader"></span>
                      </div>
                    }
                  >
                    <Switch>
                      {filteredRoutes.map((route, index) => (
                        <Route
                          key={index}
                          path={route.layout + route.path}
                          component={route.component}
                          exact={route.exact}
                        />
                      ))}
                      <Redirect to="/admin/dashboard" />
                    </Switch>
                  </Suspense>
                </PanelContainer>
              </PanelContent>
            ) : null}
            <Footer />
          </MainPanel>
        </>
      )}
    </Box>
  );
}
